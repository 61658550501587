export enum FuellCellSystemState {

    off,
    standby,
    inOperation,
    shutDown,
    frostProtection,
    deepDischargeProtection,
    transportLockProcedure,
    transportLock,
    reset,
    factoryDefaults,
    error,
    frostProtection2,
    pending,
    pending2,
    updateEfoyAccessoires
}

export function getFuellCellSystemStateString(FuellCell: FuellCellSystemState): string {
    switch (FuellCell) {
        case FuellCellSystemState.off:
            return 'Offline'
        case FuellCellSystemState.standby:
            return 'Standby'
        case FuellCellSystemState.inOperation:
            return 'In Betrieb'
        case FuellCellSystemState.shutDown:
            return 'Shut Down'
        case FuellCellSystemState.frostProtection:
            return 'Frost Protection'
        case FuellCellSystemState.deepDischargeProtection:
            return 'Deep Discharge Protection'
        case FuellCellSystemState.transportLockProcedure:
            return 'TransportLock Procedure'
        case FuellCellSystemState.transportLock:
            return 'TransportLock'
        case FuellCellSystemState.reset:
            return 'Reset'
        case FuellCellSystemState.factoryDefaults:
            return 'Factory Defaults'
        case FuellCellSystemState.error:
            return 'Error'
        case FuellCellSystemState.frostProtection2:
            return 'Frost Protection2'
        case FuellCellSystemState.pending:
            return 'Pending'
        case FuellCellSystemState.pending2:
            return 'Pending2'
        case FuellCellSystemState.updateEfoyAccessoires:
            return 'Update Efoy Accessoires'
    }
}
