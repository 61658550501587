<div *ngIf="activeProject; else noActiveProject" class="m-0">

    <div class=" card rounded-0 shadow mt-4 p-2">
        <div class="fw-semibold d-flex justify-content-between">
            <div> Zeitschaltuhr erstellen: <span class="text-danger">{{activeProject.name}} </span>
                <span class="text-danger" *ngIf="activeGroup"> | {{activeGroup.name}} </span>
            </div>
            <img (click)="onAddTimeSwitchWrapperBtnClick()" class="avs-icon-add mb-3" src="assets/icons/plus.svg"
                alt="Customstellcode hochladen">
        </div>
        <div> <span> <b>Hinweis:</b> Eine Zeitschaltuhr ist nur wirksam, wenn eine SWA existiert </span>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-3">
            <ng-container *ngFor="let ts of timerSwitches">
                <div class="card rounded-0 shadow mt-4 p-2">
                    <div class="row">
                        <span class="fw-semibold text-secondary col"> {{ ts.name}} <img class="avs-icon-delete p-2"
                                src="assets/icons/pencil.svg" alt="icon-delete-aq" style="height: 36px"
                                (click)="onEditTimerSwitchWrapperClicked(ts)"> </span>
                        <span class="col"> {{ ts.start | date: 'dd.MM.yyyy H:mm'}} Uhr</span>
                        <span class="col">
                            {{ts.stop | date: 'dd.MM.yyyy H:mm' }} Uhr</span>
                        <div class="col d-flex justify-content-end ">
                            <img *ngIf="!ts.enabled" class="avs-icon-play p-2 me-2" src="assets/icons/play-btn.svg"
                                alt="icon-play-aq" (click)="changeEnableStatus(ts)">
                            <img *ngIf="ts.enabled" class="avs-icon-pause p-2 me-2" src="assets/icons/pause-btn.svg"
                                alt="icon-pause-aq" (click)="changeEnableStatus(ts)">
                            <img class="avs-icon-delete p-2 me-2" src="assets/icons/trash.svg" alt="icon-delete-ts"
                                (click)="onDeleteTimerSwitch(ts.id!)">
                            <div *ngIf="ProjectGroupSelectInput.length > 0" class="me-2">
                                <avs-checkbox-dropdown [title]="'RouteStations'"
                                    [checkboxDropdownItems]="ProjectGroupSelectInput" [styleTitleDiv]="'width: 220px;'"
                                    [styleItemsDiv]="'width: 220px;'" [isDropdownOpen]="ts.showDropdown!"
                                    [isMultiSelectChecked]="false"
                                    (isDropdownOpenChanged)="onCheckboxDropdownChanged($event, ts.id!)"
                                    (valueChange)="this.ProjectGroupSelectInput = $event; assignmentListener($event)">
                                </avs-checkbox-dropdown>
                            </div>
                            <button (click)="assignGuids(ts.id!)" *ngIf="guidList.length > 0"
                                class="btn-outline-success btn me-2 rounded-0 " style="max-height: 34px;">
                                Zuweisen</button>
                            <button (click)="onAddAQMQTimeSwitchBtnClick(ts.id!, activeProject, true)"
                                class="btn-outline-success btn me-2 rounded-0 " style="max-height: 34px;"> AQ+</button>
                            <button (click)="onAddAQMQTimeSwitchBtnClick(ts.id!, activeProject, false)"
                                class="btn-outline-success btn me-2 rounded-0 " style="max-height: 34px;">MQ+</button>
                            <button (click)="onAddAQMQTimeSwitchBtnClick(ts.id!, activeProject, true, true)"
                                class="btn-outline-success btn rounded-0 " style="max-height: 34px;">Blinker+</button>
                        </div>
                    </div>
                    <!-- AQ Anzeige -->
                    <div class="row">
                        <div><span class="fw-semibold text-secondary"> AQs:</span> ({{ ts.aqTimers?.length }})
                            <span><img *ngIf="!ts.showAQ" style="height: 26px;" class="avs-icon-delete p-1"
                                    src="assets/icons/caret-down.svg" alt="icon-delete-aq"
                                    (click)="onHideAQClick(ts.id!)" matTooltip="Ausklappen" matTooltipPosition="right">
                                <img *ngIf="ts.showAQ" style="height: 26px;" class="avs-icon-delete p-1"
                                    src="assets/icons/caret-up.svg" alt="icon-delete-aq" (click)="onHideAQClick(ts.id!)"
                                    matTooltip="Einklappen" matTooltipPosition="right"></span>
                        </div>
                    </div>
                    <ng-container *ngIf="ts.showAQ">
                        <div *ngFor="let aq of ts.aqTimers" class="border row">
                            <div *ngIf="aq.one_time" class="col-4">
                                <div class="row mt-3 ms-4">
                                    <div class="col">Von: {{ getStartDate(aq) }}Uhr </div>
                                </div>
                                <div class="row ms-4">
                                    <div class="col">Bis: {{ getEndDate(aq) }}Uhr</div>
                                </div>
                            </div>
                            <div *ngIf="!aq.one_time" class="col-4">
                                <div class="row mt-3 ms-4">
                                    <div class="col"> Start: {{ getStartTime(aq) }} Uhr </div>
                                    <div class="col"> Ende: {{ getEndTime(aq) }} Uhr
                                    </div>
                                </div>
                                <div class="row ms-4 text-wrap">
                                    <div class="col fw-bold" [ngClass]="aq.monday ? '' : 'text-danger strikethrough'">
                                        Mo
                                    </div>
                                    <div class="col fw-bold" [ngClass]="aq.tuesday ? '' : 'text-danger strikethrough'">
                                        Di
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="aq.wednesday ? '' : 'text-danger strikethrough'">
                                        Mi</div>
                                    <div class="col fw-bold" [ngClass]="aq.thursday ? '' : 'text-danger strikethrough'">
                                        Do
                                    </div>
                                    <div class="col fw-bold" [ngClass]="aq.friday ? '' : 'text-danger strikethrough'">
                                        Fr</div>
                                    <div class="col fw-bold" [ngClass]="aq.saturday ? '' : 'text-danger strikethrough'">
                                        Sa
                                    </div>
                                    <div class="col fw-bold" [ngClass]="aq.sunday ? '' : 'text-danger strikethrough'">
                                        So</div>
                                </div>
                            </div>
                            <div class="col ">
                                <div class="d-flex justify-content-center w-100 mt-1 mb-1">
                                    <img [ngClass]="aq.tlS_Function === 0 ? 'grayscale' : ''"
                                        [src]="apiAddress + getPathByStellcodeId(aq?.tlS_Code!)">
                                </div>
                            </div>
                            <div class="col-2 mt-3 ">
                                <div class="row">Priorität: {{ priorityNames[aq.priority].name }}</div>
                                <div class="row">Funktion: {{ aq.tlS_Function == 0 ? 'Aus'
                                    :(aq.tlS_Function == 1 ? 'An' : 'Blinkend') }}
                                    <span *ngIf="aq.tlS_Function == 2">{{aq.tlS_Time}}ms</span>
                                </div>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                <div class="h-100 d-flex w-100 justify-content-end  ">
                                    <img class="avs-icon-delete p-2 mt-4" src="assets/icons/pencil.svg"
                                        alt="icon-delete-aq" style="height: 40px"
                                        (click)="onEditTimerSwitchConfigClicked(aq, 'aq')">
                                    <img class="avs-icon-delete p-2 mt-4" src="assets/icons/trash.svg"
                                        alt="icon-delete-aq" style="height: 40px"
                                        (click)="onDeleteAQTimerSwitchClicked(aq, ts.id!)">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- MQQ Anzeige -->
                    <div class="row">
                        <div> <span class="fw-semibold text-secondary">MQS </span><span>({{
                                ts.mqTimers?.length
                                }})<img *ngIf="!ts.showMQ" style="height: 26px;" class="avs-icon-delete p-1"
                                    src="assets/icons/caret-down.svg" alt="icon-delete-aq"
                                    (click)="onHideMQClick(ts.id!)" matTooltip="Ausklappen" matTooltipPosition="right">
                                <img *ngIf="ts.showMQ" style="height: 26px;" class="avs-icon-delete p-1"
                                    src="assets/icons/caret-up.svg" alt="icon-delete-aq" (click)="onHideMQClick(ts.id!)"
                                    matTooltip="Einklappen" matTooltipPosition="right"></span></div>
                    </div>
                    <ng-container *ngIf="ts.showMQ">
                        <div *ngFor="let mq of ts.mqTimers" class="border row">
                            <div *ngIf="mq.one_time" class="col-4">
                                <div class="row mt-3 ms-4 ">
                                    <div class="col">Von: {{ getStartDate(mq) }}Uhr</div>
                                </div>
                                <div class="row ms-4">
                                    <div class="col">Bis: {{ getEndDate(mq) }}Uhr</div>
                                </div>
                            </div>
                            <div *ngIf="!mq.one_time" class="col-4">
                                <div class="row mt-3 ms-4">
                                    <div class="col"> Start: {{ getStartTime(mq) }} Uhr </div>
                                    <div class="col"> Ende: {{ getEndTime(mq) }} Uhr</div>
                                </div>
                                <div class="row ms-4 text-wrap">
                                    <div class="col fw-bold" [ngClass]="mq.monday ? '' : 'text-danger strikethrough'">
                                        Mo
                                    </div>
                                    <div class="col fw-bold" [ngClass]="mq.tuesday ? '' : 'text-danger strikethrough'">
                                        Di
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="mq.wednesday ? '' : 'text-danger strikethrough'">
                                        Mi</div>
                                    <div class="col fw-bold" [ngClass]="mq.thursday ? '' : 'text-danger strikethrough'">
                                        Do
                                    </div>
                                    <div class="col fw-bold" [ngClass]="mq.friday ? '' : 'text-danger strikethrough'">
                                        Fr
                                    </div>
                                    <div class="col fw-bold" [ngClass]="mq.saturday ? '' : 'text-danger strikethrough'">
                                        Sa
                                    </div>
                                    <div class="col fw-bold" [ngClass]="mq.sunday ? '' : 'text-danger strikethrough'">
                                        So
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 mt-3 ">
                                <div class="ms-3 row">Verkehrsstufe: {{
                                    trafficNames[mq.trafic!-1].name }}
                                </div>
                                <div class="ms-3 row" *ngIf="mq.vs">Verkehrszustand: <br> {{
                                    betterVsName[mq.vs-1].name }} </div>
                            </div>
                            <div class="col-2 mt-3 ">
                                <div class="row">Priorität: {{
                                    priorityNames[mq.priority].name }}</div>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                <div class="h-100 d-flex w-100 justify-content-end  ">
                                    <img class="avs-icon-delete p-2 mt-4" src="assets/icons/pencil.svg"
                                        alt="icon-delete-aq" style="height: 40px"
                                        (click)="onEditTimerSwitchConfigClicked(mq, 'mq')">
                                    <img class="avs-icon-delete p-2 mt-4" src="assets/icons/trash.svg"
                                        alt="icon-delete-aq" style="height: 40px"
                                        (click)="onDeleteMQTimerSwitchClicked(mq, ts.id!)">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row">
                        <div><span class="fw-semibold text-secondary"> Blinker:</span>
                            ({{
                            ts.blinkerTimers?.length }})
                            <span><img *ngIf="!ts.showBlinker" style="height: 26px;" class="avs-icon-delete p-1"
                                    src="assets/icons/caret-down.svg" alt="icon-delete-aq"
                                    (click)="onHideBlinkerClick(ts.id!)" matTooltip="Ausklappen"
                                    matTooltipPosition="right">
                                <img *ngIf="ts.showBlinker" style="height: 26px;" class="avs-icon-delete p-1"
                                    src="assets/icons/caret-up.svg" alt="icon-delete-aq"
                                    (click)="onHideBlinkerClick(ts.id!)" matTooltip="Einklappen"
                                    matTooltipPosition="right"></span>
                        </div>
                    </div>
                    <ng-container *ngIf="ts.showBlinker">
                        <div *ngFor="let blinker of ts.blinkerTimers" class="border row">
                            <div *ngIf="blinker.one_time" class="col-4">
                                <div class="row mt-3 ms-4">
                                    <div class="col">Von: {{ getStartDate(blinker) }}Uhr </div>
                                </div>
                                <div class="row ms-4">
                                    <div class="col">Bis: {{ getEndDate(blinker) }}Uhr</div>
                                </div>
                            </div>
                            <div *ngIf="!blinker.one_time" class="col-4">
                                <div class="row mt-3 ms-4">
                                    <div class="col"> Start: {{ getStartTime(blinker) }} Uhr </div>
                                    <div class="col"> Ende: {{ getEndTime(blinker) }} Uhr</div>
                                </div>
                                <div class="row ms-4 text-wrap">
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.monday ? '' : 'text-danger strikethrough'"> Mo
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.tuesday ? '' : 'text-danger strikethrough'">
                                        Di
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.wednesday ? '' : 'text-danger strikethrough'">
                                        Mi
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.thursday ? '' : 'text-danger strikethrough'">
                                        Do
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.friday ? '' : 'text-danger strikethrough'">
                                        Fr
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.saturday ? '' : 'text-danger strikethrough'">
                                        Sa
                                    </div>
                                    <div class="col fw-bold"
                                        [ngClass]="blinker.sunday ? '' : 'text-danger strikethrough'">
                                        So
                                    </div>
                                </div>
                            </div>
                            <div class="col ">
                                <!-- Blinker aus -->
                                <div *ngIf="blinker.tlS_Function === 0" style="display: flex"
                                    class="d-flex justify-content-center align-content-center h-100 mt-3">
                                    <div class="p-3 rounded-5 border border-black"
                                        style="background-color: white; height: 30px; width: 30px; margin-right: 16px;">

                                    </div>
                                    <div class="p-3 rounded-5 border border-black"
                                        style="background-color: white; height: 30px; width: 30px">
                                    </div>
                                </div>
                                <!-- Blinker an -->
                                <div *ngIf="blinker.tlS_Function === 1" style="display: flex"
                                    class="d-flex justify-content-center align-content-center h-100 mt-3">
                                    <div class="p-3 rounded-5 border border-black"
                                        style="background-color: yellow; height: 30px; width: 30px; margin-right: 16px;">

                                    </div>
                                    <div class="p-3 rounded-5 border border-black"
                                        style="background-color: yellow; height: 30px; width: 30px">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 mt-3 ">
                                <div class="row">Priorität: {{
                                    priorityNames[blinker.priority].name
                                    }}
                                </div>
                                <div class="row">Funktion: {{
                                    blinker.tlS_Function == 0 ? 'Aus' :
                                    (blinker.tlS_Function
                                    == 1 ?
                                    'An' :
                                    'Blinkend') }} <span *ngIf="blinker.tlS_Function == 2">{{blinker.tlS_Time
                                        }}ms</span>
                                </div>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                <div class="h-100 d-flex w-100 justify-content-end   ">
                                    <img class="avs-icon-delete p-2 mt-3" src="assets/icons/pencil.svg"
                                        alt="icon-delete-aq" style="height: 40px"
                                        (click)="onEditTimerSwitchConfigClicked(blinker, 'flasher')">
                                    <img class="avs-icon-delete p-2 mt-3" src="assets/icons/trash.svg"
                                        alt="icon-delete-aq" style="height: 40px"
                                        (click)="onDeleteBlinkerTimerSwitchClicked(blinker!, ts.id!)">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noActiveProject>
    <p class="mt-3 fw-semibold text-secondary">Kein Projekt ausgewählt!
    </p>
</ng-template>