import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { ManualControl } from '../interfaces/manual-control.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManualControlService {

  constructor(private httpService: HttpService) { }

  public createManualControl(data: ManualControl): Observable<ManualControl> {
    return this.httpService.post<ManualControl>('ManualControl', data);
  }

  public getManualControlById(id: number): Observable<ManualControl> {
    return this.httpService.get<ManualControl>('ManualControl/' + id);
  }

  public updateManualControl(data: ManualControl): Observable<ManualControl> {
    return this.httpService.put<ManualControl>('ManualControl/' + data.id, data);
  }

  public deleteManualControl(id: number): Observable<void> {
    return this.httpService.delete<void>('ManualControl/' + id);
  }
}
