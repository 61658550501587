import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataEndChannel } from 'src/app/project/shared/interfaces/data-end-channel.interface';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { ProjectService } from 'src/app/project/shared/services/project.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'avs-fallback-image-settings',
  templateUrl: './fallback-image-settings.component.html',
  styleUrls: ['./fallback-image-settings.component.css']
})
export class FallbackImageSettingsComponent implements OnInit {

  public anzeigeprinzip: number = 3;
  public stellcode: number = 0;
  public funktions: number = 0;
  public projectId: number = 0;
  public onFunctionSelected: boolean = true;

  public images: ProjectImageData[] = [];
  public selectedImage?: ProjectImageData;
  public preSelectedImage?: ProjectImageData;
  public allImages: ProjectImageData[] = [];
  protected readonly environment = environment;

  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }/* , { name: 'Blinkend', id: 2 } */];
  public selectedFunction: SelectInput = { name: "Funktion" };

  dataEndChannel?: DataEndChannel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FallbackImageSettingsComponent>,
    private projectService: ProjectService) { }

  public ngOnInit(): void {
    this.dataEndChannel = this.data.data;
    console.log(this.dataEndChannel)
    let defaultSettings = JSON.parse(this.dataEndChannel?.defaultSettings!)
    console.log(defaultSettings)
    let fallbackSettings = defaultSettings.FallbackSettings
    console.log(fallbackSettings)

    this.dataEndChannel?.defaultSettings
    this.projectId = this.data.projectId
    console.log(this.projectId)
    if (this.projectId !== 0) {
      this.projectService.getAllRouteStationImagesWithStationId(this.data.projectId, this.data.data.routeStationId).subscribe((images) => {
        this.allImages = images
        this.images = images;
        this.preSelectedImage = this.images.find(image => image.stellcode === fallbackSettings.Stellcode)
        if (this.preSelectedImage) {
          this.onProjectImageClicked(this.preSelectedImage)
        }
        if (fallbackSettings.indicator === 0) {
          this.onFunctionItemClicked({ name: 'Aus', id: 0 })
        }
        if (fallbackSettings.indicator === 1) {
          this.onFunctionItemClicked({ name: 'An', id: 1 })
        }
      });
    }
  }

  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }
  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onProjectImageClicked(image: ProjectImageData) {
    console.log('image Clicked', image)
    if (this.images.length > 1) { this.allImages = this.images }
    if (this.images) {
      if (this.images.length >= 1) {
        this.selectedImage = image
        this.images = [];
        this.images.push(image)
      }
/*       else if (this.images.length === 1) {
        this.selectedImage = image
        this.images = [];
        this.images.push(image)
      } */
    }
   
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.onFunctionSelected = false;
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
    let funcId = item.id as number
  }

  public onSubmitBtnClicked(): void {
    this.dialogRef.close({
      anzeigeprinzip: this.anzeigeprinzip,
      stellcode: this.selectedImage?.stellcode as number,
      indicator: this.selectedFunction.id as number
    });
  }
}
