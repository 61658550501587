import { NgModule } from "@angular/core";
import { CommonModule, NgIf } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { ReportTimeAreaDropdownComponent } from "./report-time-area-dropdown/report-time-area-dropdown.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ChangelogTableComponent } from "./changelog-table/changelog-table.component";
import { ChangelogConfigurationModalComponent } from "./changelog-configuration-modal/changelog-configuration-modal.component";
import { ChangelogConfigOverviewModalComponent } from "./changelog-config-overview-modal/changelog-config-overview-modal.component";
import { UserModule } from '../user/user.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { ReportOverviewNewComponent } from './report-overview-new/report-overview-new.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { ChangelogMqGraphComponent } from "./shared/components/changelog-mq-graph/changelog-mq-graph.component";
import { ChangelogMqReportComponent } from "./shared/components/changelog-mq-report/changelog-mq-report.component";
import { MatTableModule } from '@angular/material/table';
import { ChangelogAqReportComponent } from "./shared/components/changelog-aq-report/changelog-aq-report.component";
import { DetailedReportingsComponent } from "./detailed-reportings/detailed-reportings.component";
import { RouterModule } from "@angular/router";
import { ChangelogEngineTableComponent } from "./changelog-engine-table/changelog-engine-table.component";
import { ChangelogAqEngineComponent } from "./shared/components/changelog-aq-engine/changelog-aq-engine.component";
import { ChangelogSWAReportComponent } from "./shared/components/changelog-swa-report/changelog-swa-report.component";

@NgModule({

  declarations: [
    ReportTimeAreaDropdownComponent,
    ChangelogTableComponent,
    ChangelogConfigurationModalComponent,
    ChangelogConfigOverviewModalComponent,
    ReportOverviewNewComponent,
    ChangelogMqGraphComponent,
    ChangelogMqReportComponent,
    ChangelogAqReportComponent,
    DetailedReportingsComponent,
    ChangelogEngineTableComponent,
    ChangelogAqEngineComponent,
    ChangelogSWAReportComponent
  ],
  imports: [
    NgIf,
    SharedModule,
    CommonModule,
    MatSlideToggleModule,
    UserModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressBarModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    RouterModule

  ],
  exports: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
})
export class ReportModule { }
