import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ChangelogSWAReport } from '../../interfaces/changelog-swa-report';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'avs-changelog-swa-report',
  templateUrl: './changelog-swa-report.component.html',
  styleUrls: ['./changelog-swa-report.component.css']
})
export class ChangelogSWAReportComponent {
displayedColumns: string[] = ['metaName', 'deviceName', 'timestampFrom', 'deciderName', 'decision','deciderTimestamp',  'stellcode', 'brightness', 'blinkerIndicator', 'batteryVoltage', 'timestampTo', 'duration'];
/* 'batteryVoltage', 'blinkerIndicator', 'brightness', 'deciderName', 'deciderTimestamp', 'decision', 'deviceName', 'duration', 'metaName', 'stellcode', 'timestampFrom', 'timestampTo' */
  dataSource?: MatTableDataSource<ChangelogSWAReport>;
  @Input() changelogs: ChangelogSWAReport[] = [];
  limitedChangelogs: ChangelogSWAReport[] = []
  public rowQuantity: number = 50;
  public rowRange: { start: number, end: number } = { start: 0, end: 50 };

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.changelogs);
    if (this.paginator)
      this.dataSource!.paginator = this.paginator;
    if (this.sort)
      this.dataSource!.sort = this.sort;
  }
  public ngOnChanges(): void {
    this.onDoubleLeftIconClicked();
  }

  public onRowQuantityChanged(newValue: number): void {
    this.rowRange.end = this.rowRange.end + newValue - this.rowQuantity;
    this.rowQuantity = newValue;
    this.limitRows();
  }

  public onLeftIconClicked(): void {
    if (this.rowRange.start > this.rowQuantity) {
      this.rowRange.start = this.rowRange.start - this.rowQuantity;
      this.rowRange.end = this.rowRange.end - this.rowQuantity;
    } else {
      this.rowRange.start = 0;
      this.rowRange.end = this.rowQuantity;
    }
    this.limitRows();
  }

  public onRightIconClicked(): void {
    if (this.rowRange.end + this.rowQuantity <= this.changelogs.length) {
      this.rowRange.start = this.rowRange.start + this.rowQuantity;
      this.rowRange.end = this.rowRange.end + this.rowQuantity;
    } else {
      this.rowRange.end = this.changelogs.length;
      this.rowRange.start = this.changelogs.length - this.rowQuantity;
    }
    this.limitRows();
  }

  public onDoubleLeftIconClicked(): void {
    this.rowRange.start = 0;
    this.rowRange.end = this.rowQuantity;
    this.limitRows();
  }

  public onDoubleRightIconClicked(): void {
    this.rowRange.start = this.changelogs.length - this.rowQuantity;
    this.rowRange.end = this.changelogs.length;
    this.limitRows();
  }

  private limitRows(): void {
    this.changelogs = !this.changelogs ? [] : this.changelogs;
    this.limitedChangelogs = this.changelogs.slice(this.rowRange.start, this.rowRange.start + this.rowQuantity);
  }


  lastSunday(year: number, month: number) {
    let date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);

    return date;
  }

  isCEST(d: Date): boolean {
    let testD = new Date(d);
    let year = testD.getFullYear();
    let startCET: Date = this.lastSunday(year, 3);
    let endCET: Date = this.lastSunday(year, 10);
    return !(startCET < testD && testD > endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }

}
