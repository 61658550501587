<table mat-table [dataSource]="dataSource!" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="lineDescribtion">
        <th mat-header-cell *matHeaderCellDef> Standort </th>
        <td mat-cell *matCellDef="let element"> {{element.lineDescribtion }} </td>
    </ng-container>

    <ng-container matColumnDef="samId">
        <th mat-header-cell *matHeaderCellDef> SST </th>
        <td mat-cell *matCellDef="let element"> {{element.samId}} </td>
    </ng-container>

    <ng-container matColumnDef="timestampFrom">
        <th mat-header-cell *matHeaderCellDef> Zeit (von) </th>
        <td mat-cell *matCellDef="let element"> {{element.timestampFrom | date: 'dd.MM.yyyy - H:mm':
            offset(element.timestampFrom) }} </td>
    </ng-container>

    <ng-container matColumnDef="trigger">
        <th mat-header-cell *matHeaderCellDef> Auslöser</th>
        <td mat-cell *matCellDef="let element"> {{element.trigger}} </td>
    </ng-container>

    <ng-container matColumnDef="q">
        <th mat-header-cell *matHeaderCellDef> q </th>
        <td mat-cell *matCellDef="let element"> {{element.q}} </td>
    </ng-container>
    <ng-container matColumnDef="kp">
        <th mat-header-cell *matHeaderCellDef>
            <div class="position-relative">
                K <span class="position-absolute" style="bottom: 2px; left: 9px; font-size: 8px;">p</span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.kp}} </td>
    </ng-container>
    <ng-container matColumnDef="utilization">
        <th mat-header-cell *matHeaderCellDef> <span style="font-size: 8px;"> b </span></th>
        <td mat-cell *matCellDef="let element"> {{element.utilization }} </td>
    </ng-container>
    <ng-container matColumnDef="kfzAverageSmoothSpeed">
        <th mat-header-cell *matHeaderCellDef>
            <div class="position-relative">
                V <span class="position-absolute" style="bottom: 2px; left: 9px; font-size: 8px;">kfz,g</span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.kfzAverageSmoothSpeed}} </td>
    </ng-container>
    <ng-container matColumnDef="z">
        <th mat-header-cell *matHeaderCellDef>
            <div class="position-relative">
                Z <span class="position-absolute" style="bottom: 2px; left: 9px; font-size: 8px;">i</span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.z}} </td>
    </ng-container>
    <ng-container matColumnDef="vs">
        <th mat-header-cell *matHeaderCellDef> VS </th>
        <td mat-cell *matCellDef="let element"> {{element.vs}} </td>
    </ng-container>
    <ng-container matColumnDef="deKanal">
        <th mat-header-cell *matHeaderCellDef> De-K. </th>
        <td mat-cell *matCellDef="let element"> {{element.deKanal}} </td>
    </ng-container>
    <ng-container matColumnDef="timestampTo">
        <th mat-header-cell *matHeaderCellDef> Zeit (bis) </th>
        <td mat-cell *matCellDef="let element"> {{element.timestampTo | date: 'dd.MM.yyyy - H:mm':
            offset(element.timestampTo) }} </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef> Dauer </th>
        <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>