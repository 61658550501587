import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Project } from "../shared/interfaces/project.interface";
import { ProjectGroup } from "../shared/interfaces/project-group.interface";
import { ProjectRouteStation } from "../shared/interfaces/project-route-station.interface";
import { ProjectDataService } from "../shared/services/project-data.service";
import { MatDialog } from "@angular/material/dialog";
import { DataEndChannel } from "../shared/interfaces/data-end-channel.interface";
import { RouteStationService } from "../shared/services/route-station.service";
import { GroupService } from "../shared/services/group.service";
import { DeviceService } from "../../device/shared/services/device.service";
import { DataEndChannelError } from "../shared/interfaces/data-end-channel-error.interface";
import { ProjectRouteStationWithParam } from '../shared/interfaces/project-route-station.class';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'avs-project-settings-overview',
  templateUrl: './project-settings-overview.component.html',
  styleUrls: ['./project-settings-overview.component.css']
})
export class ProjectSettingsOverviewComponent implements OnInit, OnDestroy {
  public activeProject: Project | undefined;
  public activeProjectsUsers: EditUser[] = [];
  public dataEndChannels: DataEndChannel[] = [];
  public dataEndChannelErrors: DataEndChannelError[] = [];
  public activeGroup: ProjectGroup | undefined;
  public activeRouteStation: ProjectRouteStation | undefined;
  public projectGroups: ProjectGroup[] | undefined;
  public projectRouteStations: ProjectRouteStation[] | undefined;
  public projectRouteStationsWithParams: ProjectRouteStationWithParam[] | undefined;
  public mapPositionTop: number = 0;
  public mapHeight: number = 0;
  public mapWidth?: number;
  public innerWidth: number = 0;
  public isWidthFull: boolean = false;

  private subscriptionCollection: Subscription[] = [];
  private activeRouteStationSubscription?: Subscription; /* check */
  private dataEndChannelsSubscription?: Subscription;
  private dataEndChannelErrorsSubscription?: Subscription;
  private activeProjectsUsersSubscription?: Subscription; /* check */
  private projectGroupsSubscription?: Subscription;
  private projectRouteStationsSubscription?: Subscription;
  private projectRouteStationsWithParamsSubscription?: Subscription;
  private activeGroupSubscription?: Subscription; /* check */
  private activeProjectSubscription?: Subscription; /* check */



  constructor(private projectDataService: ProjectDataService,
    public dialog: MatDialog,
    private stationService: RouteStationService,
    private groupService: GroupService,
    private deviceService: DeviceService) {
    this.activeProjectSubscription = projectDataService.activeProject.subscribe(activeProject => {
      this.activeProject = activeProject;
    });
    this.activeProjectsUsersSubscription = projectDataService.activeProjectsUsers.subscribe(users => {
      this.activeProjectsUsers = users;
    })
    this.activeGroupSubscription = projectDataService.activeGroup.subscribe(activeGroup => {
      this.activeGroup = activeGroup;
    });
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(routeStation => {
      this.activeRouteStation = routeStation;
    });
    this.projectGroupsSubscription = projectDataService.projectGroups.subscribe(groups => {
      this.projectGroups = groups;
    });
    this.projectRouteStationsSubscription = projectDataService.projectsRouteStations.subscribe(stations => {
      this.projectRouteStations = stations;
    });
    this.dataEndChannelsSubscription = projectDataService.dataEndChannels.subscribe(dataEndChannels => {
      this.dataEndChannels = dataEndChannels;
    });
    this.dataEndChannelErrorsSubscription = projectDataService.dataEndChannelErrors.subscribe(channelErrors => {
      this.dataEndChannelErrors = channelErrors;
    });
    this.projectRouteStationsWithParamsSubscription = projectDataService.projectsRouteStationsWithParam.subscribe(projectsRouteStationsWithParam => {
      this.projectRouteStationsWithParams = projectsRouteStationsWithParam;
    });
  }

  public ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.subscriptionCollection.push(this.activeProjectSubscription!);
    this.subscriptionCollection.push(this.activeProjectsUsersSubscription!);
    this.subscriptionCollection.push(this.activeGroupSubscription!);
    this.subscriptionCollection.push(this.activeRouteStationSubscription!);
    this.subscriptionCollection.push(this.projectGroupsSubscription!);
    this.subscriptionCollection.push(this.projectRouteStationsSubscription!);
    this.subscriptionCollection.push(this.dataEndChannelsSubscription!);
    this.subscriptionCollection.push(this.dataEndChannelErrorsSubscription!);
    this.subscriptionCollection.push(this.projectRouteStationsWithParamsSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onRefreshDataEndChannels(): void {
    if (!this.activeRouteStation) { return; }
    this.stationService.getDataEndChannels(this.activeRouteStation.id).subscribe(dataEndChannals => {
      if (dataEndChannals === null) {
        this.projectDataService.updateDataEndChannels([]);
      } else {
        this.projectDataService.updateDataEndChannels(dataEndChannals);
      }
    });
  }

  public onRefreshDataEndChannelErrors(): void {
    if (!this.activeRouteStation) { return; }
    this.deviceService.getAllChannelErrors(this.activeRouteStation.samId).subscribe(channelErrors => {
      this.projectDataService.updateDataEndChannelErrors(channelErrors);
    });
  }

  public onRefreshStationGroupRelation(): void {
    if (!this.activeGroup) { return; }
    this.groupService.getProjectRouteStations(this.activeGroup.id).subscribe(stations => {
      this.projectDataService.updateProjectRouteStations(stations);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  public getMapHeight() {
    if (this.innerWidth <= 1200) { this.mapHeight = 200; this.isWidthFull = true; }
    else if (1200 < this.innerWidth && this.innerWidth < 1650) {
      this.isWidthFull = false;
      if (this.activeRouteStation) { this.mapHeight = 326 }
      else if (this.activeGroup) { this.mapHeight = 236 }
      else if (this.activeProject) { this.mapHeight = 224 }
    }
    else if (this.innerWidth >= 1650) {
      this.isWidthFull = false;
      if (this.activeRouteStation) { this.mapHeight = 289; }
      else if (this.activeGroup) { this.mapHeight = 236; }
      else if (this.activeProject) { this.mapHeight = 224; }
    }
    return this.mapHeight
  }

  public getMapTop() {
    if (this.innerWidth <= 1200) {
      if (this.activeRouteStation) { this.mapPositionTop = 361 }
      else if (this.activeGroup) { this.mapPositionTop = 308 }
      else if (this.activeProject) { this.mapPositionTop = 296 }
    }
    if (this.innerWidth > 1200) {
      this.mapPositionTop = 54
    }
    return this.mapPositionTop
  }
}
