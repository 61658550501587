import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { offset } from '@popperjs/core';
import { ProjectService } from 'src/app/project/shared/services/project.service';

@Component({
  selector: 'avs-sensor-settings',
  templateUrl: './sensor-settings.component.html',
  styleUrls: ['./sensor-settings.component.css']
})
export class SensorSettingsComponent implements OnInit {
  public height: number = 0.1;
  public offset: number = 0.1;

  public serverParams: any = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SensorSettingsComponent>,
    private projectService: ProjectService) { }

  public ngOnInit(): void {
    this.serverParams = this.data.data;
    let defaultSettings = JSON.parse(this.serverParams?.defaultSettings!)
    let serverParams = defaultSettings.AdecParameter
    if (serverParams === undefined) { return; }
    this.height = serverParams.Height / 10
    this.offset = serverParams.Offset / 10

  }

  public onSubmitBtnClicked(): void {
    this.dialogRef.close({
      height: this.height * 10,
      offset: this.offset * 10

    });
  }
  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }
}
