import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";

export enum VerkehrsstufeEnum {
    Traffic1 = 1,
    Traffic2 = 2,
    Traffic3 = 3,
    Traffic4 = 4
}
export function getVerkehrsstufeString(traffic: VerkehrsstufeEnum): string {
    switch (traffic) {
        case VerkehrsstufeEnum.Traffic1: /* Wording angefragt, siehe Ticket */
            return 'freier Verkehr';
        case VerkehrsstufeEnum.Traffic2:
            return 'dichter Verkehr';
        case VerkehrsstufeEnum.Traffic3:
            return 'zähfließender Verkehr';
        case VerkehrsstufeEnum.Traffic4:
            return 'Stau';
    }
}

export function getVerkehrsstufeSelect(): SelectInput[] {
    return [
        { name: getVerkehrsstufeString(VerkehrsstufeEnum.Traffic1), id: 1 },
        { name: getVerkehrsstufeString(VerkehrsstufeEnum.Traffic2), id: 2 },
        { name: getVerkehrsstufeString(VerkehrsstufeEnum.Traffic3), id: 3 },
        { name: getVerkehrsstufeString(VerkehrsstufeEnum.Traffic4), id: 4 }
    ]
}
