import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LatestVoltage } from "../interfaces/latest-voltage.interface";
import { HttpService } from "../../../core/shared/http.service";

@Injectable({
    providedIn: 'root'
})
export class DataPointService {
    constructor(private readonly httpService: HttpService) { }

    public getRouteStationLatestVoltage(groupId: number): Observable<LatestVoltage[]> {
        return this.httpService.get<LatestVoltage[]>('Datapoint/' + groupId + '/latestVoltage')
    }
}
