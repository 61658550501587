import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataPointService } from 'src/app/project/shared/services/datapoint.service';
import { Chart } from 'chart.js/auto';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';

@Component({
  selector: 'avs-current-chart',
  templateUrl: './current-chart.component.html',
  styleUrls: ['./current-chart.component.css']
})
export class CurrentChartComponent implements OnInit {

  public chart: any;
  public samId: number;
  public now = new Date();
  public nowAsISOString: string;
  public twoDaysAgoAsString: string;
  public chartData: any;
  public labelCollection: string[] = [];
  public resultCollection: number[] = [];
  public fromDate: number = 2;
  public toDate: number = 0;
  public dataAge: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dataPointService: DataPointService,
    private reportService: ReportService,
    private dialogRef: MatDialogRef<CurrentChartComponent>) {
      let lastData = new Date(this.data.deviceParameter.solar.dateTime)
      let today = new Date()
      let diff = today.getTime() - lastData.getTime()
      let diffDays = diff / (1000 * 60 * 60 * 24)
      this.dataAge = diffDays
      if (this.dataAge!== undefined && this.dataAge > 1) { this.toDate = Math.round(this.dataAge); this.fromDate = Math.round(this.dataAge) + 2 }
    this.samId = this.data.routeStation.samId
    this.nowAsISOString = this.now.toISOString()
    let d = new Date();
    d.setDate(d.getDate() - 2);
    this.twoDaysAgoAsString = d.toISOString()
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.labelCollection = [];
    this.resultCollection = [];
    this.reportService.getRouteStationsCurrentWithFilterNew(this.samId, this.twoDaysAgoAsString, this.nowAsISOString).subscribe(x => {
      this.chartData = x;
      this.chartData.forEach((element: { measuredAt: string, current: number }) => {
        if (element.current > 15) { return }
        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection.push(dateTime)
      });
      this.chartData.forEach((element: { current: number; }) => {
        if (element.current > 15) { return }
        this.resultCollection.push(element.current)
      });
      this.createChart();
    })
  }

  public removeData(chart: any): void {
    chart.destroy()
    this.getData();
  }

  public setFromDate(date: number): void {
    this.fromDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.fromDate);
    this.twoDaysAgoAsString = d.toISOString()
  }

  public setToDate(date: number): void {
    this.toDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.toDate);
    this.nowAsISOString = d.toISOString()
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  private createChart(): void {
    this.chart = new Chart("MyChart", {
      type: 'line',

      data: {
        labels: this.labelCollection,
        datasets: [
          {
            label: "Stromverbrauch",
            data: this.resultCollection,
            backgroundColor: 'red',
            fill: false,
            borderColor: 'rgba(255, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
        ]
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }
}