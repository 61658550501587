import { Component, Input, ViewChild } from '@angular/core';
import { ChangelogMqReport } from '../../interfaces/changelog-mq-report.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { ChangelogAqFilter } from '../../interfaces/changelog-aq-filter.interface';
import { ChangelogAqReport } from '../../interfaces/changelog-aq-report';

@Component({
  selector: 'avs-changelog-aq-report',
  templateUrl: './changelog-aq-report.component.html',
  styleUrls: ['./changelog-aq-report.component.css']
})
export class ChangelogAqReportComponent {
  displayedColumns: string[] = ['metaName', 'deviceName', 'timestampFrom', 'deciderName', 'stellcode', 'brightness', 'blinkerIndicator', 'batteryVoltage', 'timestampTo', 'duration'];

  dataSource?: MatTableDataSource<ChangelogAqReport>;
  @Input() changelogs: ChangelogAqReport[] = [];
  limitedChangelogs: ChangelogAqReport[] = []
  public rowQuantity: number = 50;
  public rowRange: { start: number, end: number } = { start: 0, end: 50 };

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.changelogs);
    if (this.paginator)
      this.dataSource!.paginator = this.paginator;
    if (this.sort)
      this.dataSource!.sort = this.sort;
  }
  public ngOnChanges(): void {
    this.onDoubleLeftIconClicked();
  }

  public onRowQuantityChanged(newValue: number): void {
    this.rowRange.end = this.rowRange.end + newValue - this.rowQuantity;
    this.rowQuantity = newValue;
    this.limitRows();
  }

  public onLeftIconClicked(): void {
    if (this.rowRange.start > this.rowQuantity) {
      this.rowRange.start = this.rowRange.start - this.rowQuantity;
      this.rowRange.end = this.rowRange.end - this.rowQuantity;
    } else {
      this.rowRange.start = 0;
      this.rowRange.end = this.rowQuantity;
    }
    this.limitRows();
  }

  public onRightIconClicked(): void {
    if (this.rowRange.end + this.rowQuantity <= this.changelogs.length) {
      this.rowRange.start = this.rowRange.start + this.rowQuantity;
      this.rowRange.end = this.rowRange.end + this.rowQuantity;
    } else {
      this.rowRange.end = this.changelogs.length;
      this.rowRange.start = this.changelogs.length - this.rowQuantity;
    }
    this.limitRows();
  }

  public onDoubleLeftIconClicked(): void {
    this.rowRange.start = 0;
    this.rowRange.end = this.rowQuantity;
    this.limitRows();
  }

  public onDoubleRightIconClicked(): void {
    this.rowRange.start = this.changelogs.length - this.rowQuantity;
    this.rowRange.end = this.changelogs.length;
    this.limitRows();
  }

  private limitRows(): void {
    this.changelogs = !this.changelogs ? [] : this.changelogs;
    this.limitedChangelogs = this.changelogs.slice(this.rowRange.start, this.rowRange.start + this.rowQuantity);
  }


  lastSunday(year: number, month: number) {
    let date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);

    return date;
  }

  isCEST(d: Date): boolean {
    let testD = new Date(d);
    let year = testD.getFullYear();
    let startCET: Date = this.lastSunday(year, 3);
    let endCET: Date = this.lastSunday(year, 10);
    return !(startCET < testD && testD > endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }
  /*   displayedColumns: string[] = ['time', 'deviceId', 'projectId', 'fg', 'detyp', 'deKanal', 'jobId', 'lat', 'lng', 'rawdata', 'actions'];
    dataSource?: MatTableDataSource<ChangelogAqReport>;
    @Input() aqReports: ChangelogAqReport[] = [];
    @Input() samId: number = 0;
    @Input() projectId: number = 0;
    @Input() fromDate: string = '';
    @Input() toDate: string = '';
  
  
    @ViewChild(MatPaginator) paginator?: MatPaginator;
    @ViewChild(MatSort) sort?: MatSort;
  
    constructor(private reportService: ReportService) { }
    public ngOnInit(): void {
      this.dataSource = new MatTableDataSource(this.aqReports);
      if (this.paginator)
        this.dataSource!.paginator = this.paginator;
      if (this.sort)
        this.dataSource!.sort = this.sort;
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource!.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource!.paginator) {
        this.dataSource!.paginator.firstPage();
      }
    }
  
  
    lastSunday(year: number, month: number) {
      let date = new Date(year, month, 1, 12);
      let weekday = date.getDay();
      let dayDiff = weekday === 0 ? 7 : weekday;
      let lastSunday = date.setDate(date.getDate() - dayDiff);
  
      return date;
    }
  
    isCEST(d: Date): boolean {
      let testD = new Date(d);
      let year = testD.getFullYear();
      let startCET: Date = this.lastSunday(year, 3);
      let endCET: Date = this.lastSunday(year, 10);
      return !(startCET < testD && testD > endCET);
    }
  
    offset(d: Date): string {
      return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
    }
  
    public downloadMQReport(type: string) {
      let dateFromDate = new Date(this.fromDate);
      let dateToDate = new Date(this.toDate);
      let filter: ChangelogAqFilter = {
        samId: this.samId,
        from: this.fromDate,
        to: this.toDate,
        type: type
      };
      this.startDownload(filter);
    }
  
    private startDownload(filter: ChangelogAqFilter) {
      let filename = this.generateFileNames(filter);
  
      this.reportService.getAQReportDownload( filter).subscribe((data) => {
        const blob = new Blob([data], { type: data.type });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = filename;
        a.click();
      }
      );
    }
  
    private generateFileNames(filter: ChangelogAqFilter): string {
      if (filter.type === 'csv') {
        let filename = 'AQReport_' + filter.samId + '_' + filter.from.split('T')[0] + '_' + filter.to.split('T')[0] + '.csv';
        return filename;
      }
      if (filter.type === 'ascii') {
        let filename = 'AQReport_' + filter.samId + '_' + filter.from.split('T')[0] + '_' + filter.to.split('T')[0] + '.txt';
        return filename;
      }
      else {
        let filename = 'AQReport_' + filter.samId + '_' + filter.from.split('T')[0] + '_' + filter.to.split('T')[0] + '.pdf';
        return filename;
      }
    } */
}
