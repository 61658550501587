import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProjectRouteStationWithParam } from "../../shared/interfaces/project-route-station.class";
import { Project } from "../../shared/interfaces/project.interface";
import { ProjectImageData } from "../../shared/interfaces/project-image-data.interface";
import { ImageDataService } from "../../shared/services/image-data.service";
import { ProjectDataService } from "../../shared/services/project-data.service";
import { ProjectService } from "../../shared/services/project.service";
import { ImageService } from "../../shared/services/image.service";
import { SetImage } from "../../project-images/shared/set-image.interface";
import { DataEndChannel } from "../../shared/interfaces/data-end-channel.interface";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { DataEndChannelTypeEnum } from "../../shared/enums/data-end-channel-type.enum";
import { environment } from "../../../../environments/environment";
import { VoltageChartComponent } from '../shared/voltage-chart/voltage-chart.component';
import { CurrentChartComponent } from '../shared/current-chart/current-chart/current-chart.component';
import { StmChartComponent } from '../shared/stm-chart/stm-chart.component';
import { Subscription } from 'rxjs';
import { SpeedChartComponent } from '../shared/speed-chart/speed-chart.component';
import { SolarDetailsComponent } from '../shared/solar-details/solar-details.component';
import { BrennstoffzellenDetailsComponent } from '../shared/brennstoffzellen-details/brennstoffzellen-details.component';
import { BrennstoffzellenHistoryComponent } from '../shared/brennstoffzellen-history/brennstoffzellen-history.component';

@Component({
  selector: 'avs-schema-route-station-modal',
  templateUrl: './schema-route-station-modal.component.html',
  styleUrls: ['./schema-route-station-modal.component.css']
})
export class SchemaRouteStationModalComponent implements OnInit, OnDestroy {

  private activeProject: Project | undefined;
  public images: ProjectImageData[] = [];
  public isKanalSelectOpen: boolean = false;
  public kanalSelectInput: SelectInput[] = [];
  public selectedKanal: SelectInput | undefined;
  public isBlinker: boolean = false;
  protected readonly environment = environment;
  public projectImages: ProjectImageData[] = [];
  public imageIntervalCollection: any[] = [];
  /* public isToday: boolean = false; */


  public projectImageDataSubscribtion: Subscription;
  public imageDataSubscribtion: Subscription;
  public schemaRouteStationSubscriptionCollection: Subscription[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: ProjectRouteStationWithParam,
    private imageDataService: ImageDataService,
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<SchemaRouteStationModalComponent>,
    private imageService: ImageService,
    private alertService: AlertService,
    private dialog: MatDialog) {
    this.projectImageDataSubscribtion = imageDataService.projectRouteStationImages.subscribe(images => {
      this.images = images;
      if (images.length > 0) {
        this.rotateImagesPaths(this.images)
      }
    });
    projectDataService.activeProject.subscribe(project => {
      this.activeProject = project;
    });
    projectDataService.dataEndChannels.subscribe(endChannels => {
      this.getKanalSelect(endChannels);
    });
    this.imageDataSubscribtion = imageDataService.projectImages.subscribe(images => {
      this.projectImages = images;
    });
  }

  public ngOnInit(): void {
    if (this.activeProject) {
      this.projectService.getAllRouteStationImagesWithStationId(this.activeProject.id, this.data.routeStation.id).subscribe(images => {
        this.imageDataService.updateProjectRouteStationImages(images);
      }); 
    }
    this.schemaRouteStationSubscriptionCollection.push(this.projectImageDataSubscribtion);
    this.schemaRouteStationSubscriptionCollection.push(this.imageDataSubscribtion);
    this.setDays();
  }

  public ngOnDestroy(): void {
    this.imageIntervalCollection.forEach(id => clearInterval(id));
    this.schemaRouteStationSubscriptionCollection.forEach(sub => sub.unsubscribe());
  }

public setDays(): void {
    let lastData = new Date(this.data.routeStation.lastData)
    let today = new Date()
    let diff = today.getTime() - lastData.getTime()
    let diffDays = diff / (1000 * 60 * 60 * 24)
    this.data.routeStation.daysOld = diffDays
}

  public rotateImagesPaths(images: ProjectImageData[]): void {
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) { /* für jedes Bild, das für die RouteStation gesetzt wird, wird nun separat geprüft ob ein animatedImageId vorhanden ist */
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const idS = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]); /* iteration durchs animatedimage array, und zieht sich dasnn über die id, den path */
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);

    this.imageIntervalCollection.push(idS);
  }

  public getPathByImageId(imageId: number) {

    if (this.images) {
      let imageObject = this.images.find((x) => x.imageId == imageId)
      return imageObject?.path ? imageObject.path : 'upload/images/6480/207 - Custom Stellcode.bmp';
    }
    else return
  }

  public onCloseBtnClicked(): void {
    this.dialogRef.close();
  }

  public onDeleteStationFromSchemaClicked(): void {
    this.dialogRef.close({ data: this.data, toDelete: true });
  }

  public onShowVoltageDiagramFromSchemaClicked(): void {
    this.dialog.open(VoltageChartComponent, {
      data: this.data,
      height: '77vh',
      width: '98vw'
    });
  }

  public onShowCurrentDiagramFromSchemaClicked(): void {
    this.dialog.open(CurrentChartComponent, {
      data: this.data,
      height: '77vh',
      width: '98vw'
    });
  }

  public onShowSpeedChartFromSchemaClicked(): void {
    this.dialog.open(SpeedChartComponent, {
      data: { data: this.data, projectId: this.activeProject?.id },
      height: '77vh',
      width: '98vw'
    })
  }

  public onShowSolarDetailsFromSchemaClicked(): void {
    this.dialog.open(SolarDetailsComponent, {
      data: { data: this.data },
      /*   height: '70vh',
        width: '90vw' */
    })
  }
  public onShowBrennstoffzellenDetailsFromSchemaClicked(): void {
    this.dialog.open(BrennstoffzellenDetailsComponent, {
      data: { data: this.data },
      /*   height: '70vh',
        width: '90vw' */
    })
  }

  public onShowBrennstoffZellenVerlaufFromSchemaClicked(): void {
    this.dialog.open(BrennstoffzellenHistoryComponent, {
      data: { data: this.data },
      /*   height: '70vh',
        width: '90vw' */
    })
  }
  public onShowSTMDiagramFromSchemaClicked(): void {
    this.dialog.open(StmChartComponent, {
      data: this.data,
      height: '77vh',
      width: '98vw'
    });
  }

  public onCloseAndNavigateToStation(): void {
    this.dialogRef.close({ data: this.data, navigate: true });
  }

  public onKanalSelectClicked(): void {
    this.isKanalSelectOpen = !this.isKanalSelectOpen;
  }

  public onKanalSelectValueChange(event: SelectInput): void {
    this.selectedKanal = event;
    this.isKanalSelectOpen = false;
  }

  public onBlinkerToggle(event: any): void {
    if (event.checked) {
      let setImage = {
        stellcode: 61,
        daKanal: 2,
        deviceId: this.data.routeStation.samId,
        anzeigeprinzip: 1,
        flashingtime: 0,
        indicator: 1,
      } as SetImage
      this.imageService.setImageForStation(setImage).subscribe(() => {
        this.alertService.alert('Blinker wurde eingeschalten', AlertTypes.info);
        setTimeout(() => {
          this.dialogRef.close();
        }, 1000);
      });
    } else {
      let setImage = {
        stellcode: 61,
        daKanal: 2,
        deviceId: this.data.routeStation.samId,
        anzeigeprinzip: 1,
        flashingtime: 0,
        indicator: 0,
      } as SetImage
      this.imageService.setImageForStation(setImage).subscribe(() => {
        this.alertService.alert('Blinker wurde ausgeschalten', AlertTypes.info);
        setTimeout(() => {
          this.dialogRef.close();
        }, 1000);
      });
    }
  }

  public onSetImageClicked(stellcode: number, indicator: number): void {
    this.setImage(stellcode, indicator);
  }

  private setImage(stellcode: number, indicator: number): void {
    if (!this.selectedKanal) {
      this.alertService.alert('Kein Kanal ausgewählt', AlertTypes.warning);
      return;
    }
    let anzeigeprinzip: number;
    switch (this.selectedKanal.dataEndChannelType) {
      case DataEndChannelTypeEnum.LED:
        anzeigeprinzip = 3;
        break;
      case DataEndChannelTypeEnum.PW:
        anzeigeprinzip = 0;
        break;
      case DataEndChannelTypeEnum.BLINKER:
        anzeigeprinzip = 1;
        break;
      default:
        anzeigeprinzip = 3;
        break;
    }
    let setImage = {
      stellcode: stellcode,
      daKanal: this.selectedKanal?.id,
      deviceId: this.data.routeStation.samId,
      flashingtime: 0,
      indicator: indicator,
      anzeigeprinzip: anzeigeprinzip!,
    } as SetImage
    this.imageService.setImageForStation(setImage).subscribe(() => {
      if (indicator === 1 || indicator === 2) {
        this.alertService.alert('Stellcode ' + setImage.stellcode + ' gesetzt', AlertTypes.info);
      }
      if (indicator === 0) {
        this.alertService.alert('Stellcode ' + setImage.stellcode + ' ausgeschaltet', AlertTypes.info);
      }
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);
    });
  }

  private getKanalSelect(endChannels: DataEndChannel[]): void {
    this.kanalSelectInput = [];
    for (let filteredChannel of endChannels.filter(e =>
      (e.fg === 4) && !(e.de === 2 && e.localbusAddress === 6) && (e.de < 190))) {
      let selectInput = {
        name: 'Kanal ' + filteredChannel.de,
        id: filteredChannel.de,
        dataEndChannelType: filteredChannel.dataEndChannelType
      } as SelectInput
      this.kanalSelectInput.push(selectInput);
    }
    this.selectedKanal = this.kanalSelectInput.find(k => k.id === 1);
    let blinker = endChannels.find(e => (e.de === 2) && (e.fg === 4) && e.localbusAddress === 6);
    if (blinker) { this.isBlinker = true; }
  }


  // function to check if data.deviceParameter.lastData is less then 14 days old

  public isDataOlderThan14Days(): boolean {
    let lastData = this.data.routeStation.lastData;
    let lastDataDate = new Date(lastData);
    let currentDate = new Date();
    let diff = currentDate.getTime() - lastDataDate.getTime();
    let diffDays = diff / (1000 * 60 * 60 * 24);
    if (diffDays > 14) {
      return true;
    } else {
      return false;
    }
  }
}
