import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProjectTimeSwitchBody } from '../../shared/interfaces/project-time-switch-body';
@Component({
  selector: 'avs-add-time-switch',
  templateUrl: './add-time-switch.component.html',
  styleUrls: ['./add-time-switch.component.css']
})
export class AddTimeSwitchComponent implements OnInit {
  public startDate?: NgbDate;
  public endDate?: NgbDate;
  public starttime: string = "08:00:00";
  public endtime: string = "16:00:00"
  public name: string = '';
  public untouched: boolean = true;
  public editingActive: boolean = false;
  public inputStart?: NgbDate;
  public inputEnd?: NgbDate;

  constructor(public dialogRef: MatDialogRef<AddTimeSwitchComponent>,
    @Inject(MAT_DIALOG_DATA) public ts: ProjectTimeSwitchBody,
  ) { }

  public ngOnInit(): void {
    if (this.ts) {
      this.setEditingData()
    }
  }


  public onStartDateSelect(newValue: NgbDate): void {
    this.startDate = newValue;
    this.inputStart = newValue;
  }

  public onEndDateSelect(newValue: NgbDate): void {
    this.endDate = newValue;
    this.inputEnd = newValue;
  }

  public onEnterStartTimeChange(newValue: any): void {
    this.starttime = newValue;
  }

  public onEnterEndTimeChange(newValue: any): void {
    this.endtime = newValue;
  }

  public onNameChange(newValue: string): void {
    this.name = newValue;
    this.untouched = false;
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  public onSubmitClicked(): void {
    this.dialogRef.close({ startDate: this.startDate, endDate: this.endDate, starttime: this.starttime, endtime: this.endtime, name: this.name })
  }

  public onEditClicked(): void {
    this.dialogRef.close({ startDate: this.startDate, endDate: this.endDate, starttime: this.starttime, endtime: this.endtime, name: this.name, id: this.ts.id })
  }

  private setEditingData() {
    this.name = this.ts.name;
    this.editingActive = true;

    let startDateU = this.ts.start as unknown
    let startDate = (startDateU as string).split('T')
    let stopDateU = this.ts.stop as unknown
    let endDate = (stopDateU as string).split('T')
    let finalizedStartDate = startDate[0].split('-')
    let finalizedEndDate = endDate[0].split('-')

    let startMinusZ = startDate[1].substring(0, startDate[1].length - 1)
    let endMinusZ = endDate[1].substring(0, endDate[1].length - 1)

    let ngbStartDate = new NgbDate(parseInt(finalizedStartDate[0]), parseInt(finalizedStartDate[1]), parseInt(finalizedStartDate[2]))
    let endStartDate = new NgbDate(parseInt(finalizedEndDate[0]), parseInt(finalizedEndDate[1]), parseInt(finalizedEndDate[2]))
    this.onStartDateSelect(ngbStartDate);
    this.onEndDateSelect(endStartDate);

    let startDate2 = new Date(this.ts.start)
    let hoursStart = startDate2.getHours()
    let minutesStart = startDate2.getMinutes()
    let secondsStart = startDate2.getSeconds()

    let endDate2 = new Date(this.ts.stop)
    let hoursEnd = endDate2.getHours()
    let minutesEnd = endDate2.getMinutes()
    let secondsEnd = endDate2.getSeconds()

    let startTimeFormatted = String(this.checkIf2Digits(hoursStart) + ':' + this.checkIf2Digits(minutesStart) + ':' + this.checkIf2Digits(secondsStart));
    let endTimeFormatted = String(this.checkIf2Digits(hoursEnd) + ':' + this.checkIf2Digits(minutesEnd) + ':' + this.checkIf2Digits(secondsEnd));

    this.onEnterStartTimeChange(startTimeFormatted);
    this.onEnterEndTimeChange(endTimeFormatted);
  }

  public checkIf2Digits(x: number): any {
    if (x < 10) {
      return '0' + x
    }
    else {
      return x
    }
  }
}
