import { Component, OnDestroy } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ProjectDataService } from '../shared/services/project-data.service';
import { Subscription } from 'rxjs';
import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { Project } from '../shared/interfaces/project.interface';
import { SchemaRouteStationModalComponent } from '../project-schema/schema-route-station-modal/schema-route-station-modal.component';
import { MatDialog } from "@angular/material/dialog";
import { ProjectRouteStationWithParam } from '../shared/interfaces/project-route-station.class';
import { environment } from 'src/environments/environment';
import { ImageDataService } from '../shared/services/image-data.service';
import { ProjectImageData } from '../shared/interfaces/project-image-data.interface';
import { ProjectMarkerObject } from './shared/interfaces/project-marker-object';
import { GroupMarkerObject } from './shared/interfaces/group-marker-object';
import { RouteStationMarkerObject } from './shared/interfaces/route-station-marker-object';
import { Location } from '@angular/common';
import { set } from 'lodash';
import { GroupService } from '../shared/services/group.service';
const loader: Loader = new Loader({
  apiKey: "AIzaSyBcos3aEaS6CkhPm_2YPTrbv3f1XUH6Hf0",
  version: "weekly"
});
@Component({
  selector: 'avs-project-googlemaps',
  templateUrl: './project-googlemaps.component.html',
  styleUrls: ['./project-googlemaps.component.css']
})
export class ProjectGooglemapsComponent implements OnDestroy { /* ondelete fehlt noch, um subs zu dismissen */

  public map?: google.maps.Map;
  public isMapLoaded: boolean = false;
  public center: google.maps.LatLngLiteral = { lat: 51.095123, lng: 10.241483 };
  public activeProject: Project | undefined;
  public projects: Project[] = [];
  public projectGroups: ProjectGroup[] = [];
  public allGroups: ProjectGroup[] = [];
  public groupsRouteStationsWithParams: ProjectRouteStationWithParam[] | undefined;
  public activeGroup: ProjectGroup | undefined;
  public projectPins?: ProjectMarkerObject[] = [];
  public projectMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  public groupPins?: GroupMarkerObject[];
  public groupMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  public routeStationPins?: RouteStationMarkerObject[];
  public routeStationMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  public activeRouteStation: ProjectRouteStation | undefined;
  public projectImagesSubscription?: Subscription;
  public projectsSubscription?: Subscription;
  public groupsSubscription?: Subscription;
  public routeStationsSubscription?: Subscription;
  public routeStationsWithParamSubscription?: Subscription;
  public projectRouteStationsWithParamSubscription?: Subscription;
  public images: ProjectImageData[] = [];
  public allImages: ProjectImageData[] = [];
  public projectClusterer?: MarkerClusterer
  public apiAddress: string = environment.apiAddress;
  private transitLayer?: google.maps.TransitLayer;
  private trafficLayer?: google.maps.TrafficLayer;
  public isTransitLayerActive: boolean = false;
  public isTrafficLayerActive: boolean = false;

  public timer: any;
  public countdown: number = 30;
  public isTimerActive: boolean = false;
  public isMapDrawn: boolean = false;
  public isGroupBackButtonDrawn: boolean = false;
  public isRouteStationBackButtonDrawn: boolean = false;


  constructor(private readonly projectDataService: ProjectDataService,
    private dialog: MatDialog, private imageDataService: ImageDataService,
    private location: Location,
    private groupService: GroupService) {
    this.projectsSubscription = projectDataService.filteredProjects.subscribe(projects => {
      if (projects === undefined) { return }
      else {
        this.destroyGroupMarkers();
        this.destroyRouteStationMarkers();
        if (projects !== this.projects) {
          this.showProjectClusterer(false);
          this.createProjectPins(projects);
          this.projects = projects;
          if (this.isMapLoaded === false && this.projects.length > 0) {
            setTimeout(() => {
              this.initMap();

              this.isMapLoaded = true;
            }, 50);
          }
          this.setProjectMarkers();
        }
      }
    });
    this.groupsSubscription = projectDataService.projectGroups.subscribe(groups => {
      if (this.projectPins?.length !== this.projectMarkers.length) { this.setProjectMarkers(); }
      if (groups.length === 0 && this.projectPins!.length > 0) { this.showProjectClusterer(true); this.showGroupBackButton(false) }
      if (groups.length === 0 && this.projectPins?.length === 0) { this.createProjectPins(this.projects); this.showGroupBackButton(false) }
      this.projectGroups = groups;
      if (this.routeStationMarkers.length > 0) this.destroyRouteStationMarkers();
      if (groups.length === 0) {
        this.destroyGroupMarkers();
      }
      if (groups.length > 0) {
        this.generateGroupPins(groups);

        this.showGroupBackButton(true);
      }
    });
    this.projectRouteStationsWithParamSubscription = projectDataService.projectsRouteStationsWithParam.subscribe(routeStations => {
      this.destroyRouteStationMarkers();
      if (routeStations === undefined) { this.generateGroupPins(this.projectGroups); this.showRouteStationBackButton(false, true); this.stopTimer(); this.isTimerActive = false; return }
      this.groupsRouteStationsWithParams = routeStations;
      if (routeStations)
        if (routeStations.length > 0) {
          this.destroyGroupMarkers();
          this.showGroupBackButton(false);
          this.generateRouteStationPins(routeStations);
          this.showRouteStationBackButton(true);
          this.isTimerActive = true;
          this.countdown = 30;
          this.startTimer();

        }
    });
    this.projectImagesSubscription = imageDataService.projectImages.subscribe(images => {
      this.images = images;
      this.allImages = images;
    });
  }

  public ngOnDestroy(): void {
    this.projectsSubscription?.unsubscribe();
    this.groupsSubscription?.unsubscribe();
    this.routeStationsSubscription?.unsubscribe();
    this.projectRouteStationsWithParamSubscription?.unsubscribe();
    this.projectImagesSubscription?.unsubscribe();
    this.stopTimer();
  }

  private createProjectPins(projects: Project[]): void {
    this.projectPins = [];
    this.projects = projects
    this.allGroups = [];
    projects.forEach(project => {
      this.allGroups.push(...project.groups);
    }
    )
    projects.forEach(project => {
      this.projectPins!.push({
        lat: project.latitude,
        lng: project.longitude,
        title: project.name,
        content: 'content',
        active: project.isActive,
        id: project.id
      });
    })
  }

  public stopTimer() {
    if (this.timer)
      clearInterval(this.timer)
  }

  private startTimer() {
    this.stopTimer();
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        this.triggerReload();
      }
    }, 1000);
  }

  public triggerReload() {
    let groupId = this.groupsRouteStationsWithParams?.[0].routeStation.groupId;
    if (groupId !== undefined)
      this.groupService.getProjectRouteStations(groupId).subscribe(routeStations => {
        this.projectDataService.updateProjectRouteStations(routeStations);
      });
    this.countdown = 30;
  }

  public generateGroupPins(groups: ProjectGroup[]): void {
    this.destroyGroupMarkers();
    this.destroyProjectMarkers();

    this.groupPins = [];
    let itemsProcessed = 0;
    groups.forEach(group => {

      this.groupPins!.push({
        lat: group.latitude,
        lng: group.longitude,
        title: group.name,
        content: 'content',
        active: group.isActive,
        id: group.id,
        street: group.routeName
      })
      itemsProcessed++;
      if (itemsProcessed === groups.length) {
        this.setGroupMarker(this.groupPins!, 'group');
      }
    }
    )
    if (groups.length > 0) {
      let projectId = groups[0].projectId;
      let project = this.projects.find(p => p.id === projectId);
      if (this.map !== undefined) {
        let bounds = new google.maps.LatLngBounds();
        groups.forEach(g => { bounds.extend({ lat: g.latitude, lng: g.longitude }) });
        this.map?.fitBounds(bounds);
        this.showGroupBackButton(true)
      }
    }
    else {
      this.map?.setZoom(8);
      this.map?.setCenter(this.center);
    }
  }
  public generateRouteStationPins(routeStations: ProjectRouteStationWithParam[]): void {
    this.showGroupBackButton(false);
    this.routeStationPins = [];
    let itemsProcessed = 0;
    routeStations.forEach(routeStation => {
      this.routeStationPins!.push({
        lat: routeStation.routeStation.latitude,
        lng: routeStation.routeStation.longitude,
        title: routeStation.routeStation.name,
        content: 'content',
        id: routeStation.routeStation.samId,
        stellcode: routeStation?.deviceParameter.led.stellcode,
        name: routeStation.routeStation.name,
        blinker: routeStation.deviceParameter.blinker?.indicator,
        indicatorLed: routeStation.deviceParameter.led.indicator,
        isBrennstoffzelle: this.checkForBrennstoffZelle(routeStation.deviceParameter),
        patrone1: routeStation.deviceParameter.brennstoffZelle?.fuellgradPatrone1,
        patrone2: routeStation.deviceParameter.brennstoffZelle?.fuellgradPatrone2
      })
      itemsProcessed++;
      if (itemsProcessed === routeStations.length) {
        this.setrouteStationMarkers(this.routeStationPins!, 'routeStation');
      }
    })
    let bounds = new google.maps.LatLngBounds();
    if (this.map !== undefined) {
      routeStations.forEach(rs => { bounds.extend({ lat: rs.routeStation.latitude, lng: rs.routeStation.longitude }) });
      this.map?.fitBounds(bounds);
    }
  }

  public checkForBrennstoffZelle(deviceParameter: any): boolean {
    if (deviceParameter.brennstoffZelle === null) {
      return false;
    }
    else return true;
  }

  public destroyGroupMarkers(): void {
    this.groupMarkers.forEach(marker => {
      marker.map = null;
    })
  }

  public destroyProjectMarkers(): void {
    this.projectMarkers.forEach(marker => {
      marker.map = null;
    })
  }

  public destroyRouteStationMarkers(): void {
    this.routeStationMarkers.forEach(marker => {
      marker.map = null;
    })
  }

  public getAllGroups() {
    this.allGroups = [];
    this.projects.forEach(project => {
      this.allGroups.push(...project.groups);
    })
  }

  async initMap() {
    loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

      this.transitLayer = new google.maps.TransitLayer();
      this.trafficLayer = new google.maps.TrafficLayer();

      this.map = new Map(document.getElementById("map") as HTMLElement, {
        zoom: 6,
        center: this.center,
        mapId: "avs-map",
        mapTypeControl: true,
        scaleControl: true,
        maxZoom: 18,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
        },

        gestureHandling: "greedy",

      });
      if (this.projectPins!.length > 0) {
        this.setProjectMarkers();
      }
      this.addGroupBackButton();
      this.addRouteStationBackButton();

      this.onOffButtonTransitLayer();
      this.onOffButtonTrafficLayer();
      this.isMapDrawn = true;
    })
  }

  enterProject(markerView: any, property: any) {
    let project = this.projects.find(p => p.id === property.id);
    if (this.activeProject === project) {
      if (this.activeGroup !== undefined) { this.projectDataService.updateActiveGroup(undefined); return }
      this.projectDataService.updateActiveProject(undefined);
    } else {
      this.projectDataService.updateActiveProject(project);
      this.map?.setCenter({ lat: project!.latitude, lng: project!.longitude });
      this.map?.setZoom(13);
    }
  }

  enterGroup(markerView: any, property: any) {
    let group = this.projectGroups.find(g => g.id === property.id);
    if (this.activeGroup === group) {
      this.projectDataService.updateActiveGroup(undefined);
    } else {
      this.projectDataService.updateActiveGroup(group);
      this.map?.setCenter({ lat: group!.latitude, lng: group!.longitude });
      this.map?.setZoom(16);
    }
  }

  enterRouteStation(markerView: any, property: any) {
    let routeStation = this.groupsRouteStationsWithParams!.find(rs => rs.routeStation.samId === property.id);

    if (this.activeRouteStation === routeStation) {
      this.projectDataService.updateActiveRouteStation(undefined);
    } else {
      this.projectDataService.updateActiveRouteStation(routeStation?.routeStation);
      this.dialog.open(SchemaRouteStationModalComponent, { data: routeStation }).afterClosed().subscribe(
        result => {

        });
    }
  }

  toggleHighlight(markerView: any, property: any) {
    if (markerView.content.classList.contains("highlight")) {
      markerView.content.classList.remove("highlight");
      markerView.zIndex = null;
    } else {
      markerView.content.classList.add("highlight");
      markerView.zIndex = 1;
    }
  }

  showProjectClusterer(bool: boolean) {
    if (bool) {
      if (this.projectPins?.length === this.projectMarkers.length) {
        this.projectMarkers.forEach(marker => {
          marker.map = this.map
        })
        this.projectClusterer?.addMarkers(this.projectMarkers);
      }
      else { this.createProjectPins(this.projects); }
    }
    else {
      this.projectClusterer?.removeMarkers(this.projectMarkers);
      this.projectMarkers.forEach(marker => {
        marker.map = null
      })
    }
  }

  setProjectMarkers() {
    if (this.map === undefined) return;

    this.projectMarkers = this.projectPins!.map((pin, i) => {
      const AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        content: this.buildContent(pin),
        position: { lat: pin.lat, lng: pin.lng },
        title: pin.title,
      });
      AdvancedMarkerElement.addListener("click", () => {
        this.enterProject(AdvancedMarkerElement, pin);
      });

      return AdvancedMarkerElement;
    });
    this.projectClusterer = new MarkerClusterer({ markers: this.projectMarkers, map: this.map });
  }

  public addGroupBackButton() {
    const backButton = document.createElement("button");
    backButton.classList.add("group-button");
    backButton.innerHTML = "Zurück";
    backButton.addEventListener("click", () => {
      this.projectDataService.updateActiveGroup(undefined);
      this.destroyGroupMarkers();
      this.showProjectClusterer(true); this.showGroupBackButton(false);
      let bounds = new google.maps.LatLngBounds();
      this.projects.forEach(p => { bounds.extend({ lat: p.latitude, lng: p.longitude }) });
      this.map?.fitBounds(bounds);

    });
    this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(backButton);
    this.isGroupBackButtonDrawn = true;
  }

  public addRouteStationBackButton() {
    const backButton = document.createElement("button");
    backButton.classList.add("route-station-button");
    backButton.innerHTML = " Zurück";
    backButton.addEventListener("click", () => {
      this.destroyRouteStationMarkers();
      this.generateGroupPins(this.projectGroups);
      this.showRouteStationBackButton(false);
      this.showGroupBackButton(true);
      let bounds = new google.maps.LatLngBounds();
      this.projectGroups.forEach(g => { bounds.extend({ lat: g.latitude, lng: g.longitude }) });
      this.map?.fitBounds(bounds);
      this.projectDataService.updateActiveGroup(this.activeGroup);
    });
    this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(backButton);
    this.isRouteStationBackButtonDrawn = true;

  }

  public showGroupBackButton(bool: boolean) {
    if (bool) {
      let groupbutton = document.querySelector('.group-button') as HTMLElement;
      if (groupbutton === null) {
        setTimeout(() => {
          this.showGroupBackButton(bool);
        }, 500);
      }
      if (groupbutton !== null) groupbutton.style.display = 'flex'
    }
    else {
      let groupbutton = document.querySelector('.group-button') as HTMLElement;
      if (groupbutton)
        groupbutton.style.display = 'none'
    }
  }

  public showRouteStationBackButton(bool: boolean, groupBool?: boolean) {

    if (bool) { this.showProjectClusterer(false); }
    if (this.map === undefined) {
      setTimeout(() => {
        this.showRouteStationBackButton(bool, groupBool);
      }, 500);
      return;
    }
    if (bool) {
      let routeStationButton = document.querySelector('.route-station-button') as HTMLElement;
      if (routeStationButton) { routeStationButton.style.display = 'flex' }
      else setTimeout(() => {
        this.showRouteStationBackButton(bool, groupBool);
      }, 500);
    }
    else {
      let routeStationButton = document.querySelector('.route-station-button') as HTMLElement;
      if (routeStationButton) { routeStationButton.style.display = 'none' }
      if (groupBool && this.projectGroups.length > 0) {

        this.generateGroupPins(this.projectGroups);
        this.showGroupBackButton(true);
      }
    }
  }

  public onOffButtonTransitLayer() {
    const transitLayerButton = document.createElement("button");
    transitLayerButton.classList.add("transit-layer-button");
    transitLayerButton.innerHTML = "Transit (aus)";

    transitLayerButton.addEventListener("click", () => {
      if (this.isTransitLayerActive) {
        this.transitLayer?.setMap(null);
        this.isTransitLayerActive = false;
        transitLayerButton.innerHTML = "Transit (aus)";
      }
      else {
        if (this.map === undefined) return;
        this.transitLayer?.setMap(this.map);
        this.isTransitLayerActive = true;
        transitLayerButton.innerHTML = "Transit (an)";
      }
    });
    this.map?.controls[google.maps.ControlPosition.TOP_RIGHT].push(transitLayerButton);
  }

  public onOffButtonTrafficLayer() {
    const trafficLayerButton = document.createElement("button");
    trafficLayerButton.classList.add("traffic-layer-button");
    trafficLayerButton.innerHTML = "Traffic (aus)";

    trafficLayerButton.addEventListener("click", () => {
      if (this.isTrafficLayerActive) {
        this.trafficLayer?.setMap(null);
        this.isTrafficLayerActive = false;
        trafficLayerButton.innerHTML = "Traffic (aus)";
      }
      else {
        if (this.map === undefined) return;
        this.trafficLayer?.setMap(this.map);
        this.isTrafficLayerActive = true;
        trafficLayerButton.innerHTML = "Traffic (an)";
      }
    });
    this.map?.controls[google.maps.ControlPosition.TOP_RIGHT].push(trafficLayerButton);
  }

  setGroupMarker(pins: GroupMarkerObject[], markerType: string) {
    if (this.map === undefined) return;

    this.projectClusterer?.clearMarkers();
    for (let i = 0; i < pins.length; i++) {

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: pins[i].lat, lng: pins[i].lng },
        map: this.map,
        content: this.buildGroupContent(pins[i])
      });
      marker.addListener("click", () => {
        this.enterGroup(marker, pins[i]);
      });
      this.groupMarkers.push(marker);
    }
  }

  setrouteStationMarkers(pins: RouteStationMarkerObject[], markerType: string) {
    for (let i = 0; i < pins.length; i++) {
      if (this.map === undefined) return;
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: pins[i].lat, lng: pins[i].lng },
        map: this.map,
        content: this.buildRouteStationContentNew(pins[i]),
        title: pins[i].title
      });
      marker.addListener("click", () => {
        this.enterRouteStation(marker, pins[i]);
      });
      this.routeStationMarkers.push(marker);
    }
  }

  public getPathByStellcode(stellcode: number) {
    if (this.allImages) {
      let imageObject = this.allImages.find((x) => x.stellcode == stellcode)
      return imageObject?.path as string
    }
    else return
  }

  buildContent(property: ProjectMarkerObject) {
    property.groups = this.allGroups.filter(g => g.projectId === property.id);
    let groupContent = this.groupContentInProject(property);
    const content = document.createElement("div");
    content.classList.add("property");
    if (property.active) {
      content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-folder-tree" title=" ${property.title}"></i>
        <span class="fa-sr-only"> ${property.title}</span>
         <div class="details">   ${property.title} </div>
    </div>
    <div class="group-container">
    ${groupContent}
    </div>
          `;
      return content;
    }
    else
      content.innerHTML = `
  <div class="icon">
      <i aria-hidden="true" class="fa fa-icon fa-folder-tree deactivated" title=" ${property.title}"></i>
      <span class="fa-sr-only"> ${property.title}</span>
        <div class="details">
    ${property.title}
  </div>
  </div>
  <div class="group-container">
    ${groupContent}
    </div>
        `;
    return content;
  }

  groupContentInProject(property: ProjectMarkerObject): string {
    let htmlContent = '';
    property.groups?.forEach(group => {
      htmlContent += `<span> ${group.name} </span> `
    })
    if (property.groups?.length === 0) { return '<span> Keine Gruppen </span>' }
    else return htmlContent
  }

  buildGroupContent(property: GroupMarkerObject) {
    let routeStationCount = this.routeStationcountInGroup(property)

    const content = document.createElement("div");
    content.classList.add("property");
    if (property.active) {
      content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-folder" title="folder"></i>
        <span class="fa-sr-only">folder</span>
            <div class="details">
      ${property.title}
    </div>
         
     </div>
   <span class="group-container"> ${property.street} </span>
      <div class="group-container">
   
    ${routeStationCount}
    </div>`;
      return content;
    }
    else
      content.innerHTML = `
  <div class="icon">
      <i aria-hidden="true" class="fa fa-icon fa-folder deactivated" title="folder"></i>
      <span class="fa-sr-only">folder</span>
        <div class="details">
    ${property.title}
  </div>
   </div>
 <span class="group-container"> ${property.street} </span>
    <div class="group-container">
    ${routeStationCount}
    </div>`;
    return content;
  }

  public routeStationcountInGroup(property: GroupMarkerObject): string {
    let htmlContent = '';
    let routeStations = this.projectGroups.find(g => g.id === property.id)?.routeStations;
    if (routeStations) {
      if (routeStations.length > 0) {
        htmlContent += `<span> Anzahl RouteStations: ${routeStations.length}  </span> `
        return htmlContent
      }
      else return `<span> Anzahl RouteStations: ${routeStations.length}  </span> `
    }
    else return `<span> Anzahl RouteStations: 0  </span> `
  }

  public createBlinkerContent(property: RouteStationMarkerObject): string {
    if (property.blinker === 'einschalten') {
      return `<div class="d-flex" style="margin-bottom: -16px">
      <div class=" rounded-5 border border-black" style="background-color: yellow; height: 14px; width: 14px; margin-right: 12px; padding: 10px;">
                </div>
                      <div class="rounded-5 border border-black"
                    style="background-color: yellow; height: 14px; width: 14px; padding: 10px;">
                </div>
                </div>
`    }
    if (property.blinker === 'ausschalten') {
      return `   <div class="d-flex" style="margin-bottom: -16px">
      <div class=" rounded-5 border border-black" style="background-color: white; height: 14px; width: 14px; margin-right: 12px;padding: 10px;">
                </div>
                      <div class="rounded-5 border border-black"
                    style="background-color: white; height: 14px; width: 14px; padding: 10px;">
                </div>
                </div>`
    }
    else return ''
  }

  public createRouteStationHeader(property: RouteStationMarkerObject): string {
    return `<div class="details">
    <span class="text-center"> ${property.name} </span>
    
    </div>
    <span class="text-center group-container"> SAM ID: ${property.id} </span>`
  }

  public createBodyContent(property: RouteStationMarkerObject): string {
    if (property.indicatorLed === 'ausschalten') {
      return `<img style="height: 60px" src="${location.origin}/assets/led_default.svg">`
    }
    if (property.indicatorLed === 'einschalten') {
      return `<img style="height: 60px" src="${this.apiAddress + this.getPathByStellcode(property.stellcode!)}">`
    }
    if (property.indicatorLed === null && property.isBrennstoffzelle !== true) {
      return `<img style="height: 60px" src="${location.origin}/assets/icons/data-end-channel/radar_aq.svg">`
    }
    if (property.isBrennstoffzelle === true) {
      return `<img style="height: 60px" src="${location.origin}/assets/icons/gas_meter.svg">
      <span class="text-center group-container"> Patrone 1: ${property.patrone1} %</span>
      <span class="text-center group-container"> Patrone 2: ${property.patrone2} %</span>`
    }
    else return '<h1> Error </h1>'
  }

  buildRouteStationContentNew(property: RouteStationMarkerObject) {
    let path = this.getPathByStellcode(property.stellcode!);
    let fullPath = this.apiAddress + path;
    if (property.indicatorLed === 'ausschalten') {
      fullPath = location.origin + '/assets/led_default.svg'
    }
    const content = document.createElement("div");
    content.classList.add("propertyRS"); /* kopieren und ohne hover mit anderem namen */

    if (path === undefined && property.stellcode !== 0) {
      content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-map-pin" title="map-pin"></i>
        <span class="fa-sr-only">map-pin</span>
    </div>
    <div class="details">
      ${property.title}
    </div>
          `;
      return content;
    }

    /* content.innerHTML += this.createRouteStationHeader(property) */

    content.innerHTML += this.createBlinkerContent(property)

    content.innerHTML += this.createBodyContent(property)

    return content;
  }
}
