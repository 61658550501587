import { MapPositionService } from '../../project-map/shared/map-position.service';
import { Project } from '../interfaces/project.interface';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProjectGroup } from "../interfaces/project-group.interface";
import { ProjectRouteStation } from "../interfaces/project-route-station.interface";
import { ProjectService } from './project.service';
import { ProjectDataSessionStorageService } from "./project-data-session-storage.service";
import { DataEndChannel } from "../interfaces/data-end-channel.interface";
import { RouteStationService } from "./route-station.service";
import { GroupService } from "./group.service";
import { ImageDataService } from "./image-data.service";
import { ProjectRouteStationWithParam } from "../interfaces/project-route-station.class";
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { DataEndChannelError } from "../interfaces/data-end-channel-error.interface";
import { ProjectSessionStorageData } from '../interfaces/project-session-storage-data.interface';
import { ProjectMenuData } from "../../project-menu/shared/project-menu-data.interface";
import { DataPointService } from './datapoint.service';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';
import { UserDataResponse } from 'src/app/user/shared/interfaces/login-data-response.interface';
import { UserDataService } from 'src/app/user/shared/services/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {
  private projectSubject$ = new BehaviorSubject<Project[]>([]);
  public projects = this.projectSubject$.asObservable();
  private activeProjectsUsersSubject = new BehaviorSubject<any>([]);
  public activeProjectsUsers = this.activeProjectsUsersSubject.asObservable();
  private projectGroupSubject$ = new BehaviorSubject<ProjectGroup[]>([]);
  public projectGroups = this.projectGroupSubject$.asObservable();
  private projectRouteStationSubject$ = new BehaviorSubject<ProjectRouteStation[]>([]);
  public projectsRouteStations = this.projectRouteStationSubject$.asObservable();
  private projectRouteStationWithParamSubject$ = new BehaviorSubject<ProjectRouteStationWithParam[] | undefined>(undefined);
  public projectsRouteStationsWithParam = this.projectRouteStationWithParamSubject$.asObservable();
  private activeProjectSubject$ = new BehaviorSubject<Project | undefined>(undefined);
  public activeProject = this.activeProjectSubject$.asObservable();
  private activeGroupSubject$ = new BehaviorSubject<ProjectGroup | undefined>(undefined);
  public activeGroup = this.activeGroupSubject$.asObservable();
  private activeRouteStationSubject$ = new BehaviorSubject<ProjectRouteStation | undefined>(undefined);
  public activeRouteStation = this.activeRouteStationSubject$.asObservable();

  private dataEndChannelSubject$ = new BehaviorSubject<DataEndChannel[]>([]);
  public dataEndChannels = this.dataEndChannelSubject$.asObservable();
  private dataEndChannelErrorsSubject$ = new BehaviorSubject<DataEndChannelError[]>([]);
  public dataEndChannelErrors = this.dataEndChannelErrorsSubject$.asObservable();

  public filteredPorjectsSubject$ = new BehaviorSubject<Project[]>([]);
  public filteredProjects = this.filteredPorjectsSubject$.asObservable();



  constructor(private projectService: ProjectService,
    private groupService: GroupService,
    private routeStationService: RouteStationService,
    private mapPositionService: MapPositionService,
    private projectDataSessionService: ProjectDataSessionStorageService,
    private imageDataService: ImageDataService,
    private deviceService: DeviceService,
    private dataPointService: DataPointService,
    private readonly userDataService: UserDataService
  ) { }

  public updateProjects(projects: Project[]): void {
    this.projectSubject$.next(projects);
    this.mapPositionService.setProjectMapPoints(projects)
  }

  public updateProjectsUser(user: EditUser[]): void {
    this.activeProjectsUsersSubject.next(user);
  }

  public updateProjectGroups(projectGroups: ProjectGroup[]): void {
    this.projectGroupSubject$.next(projectGroups);
    if (projectGroups[0]) {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionService.getProjectSessionData();
      this.mapPositionService.setGroupMapPoints(projectGroups, activeProjectData.activeProjectId);
    }
  }

  public updateProjectDataFromSession(sessionData: ProjectMenuData): void {

    this.updateProjects(sessionData.projects);
    this.updateActiveProject(sessionData.activeProject, true);
    this.updateProjectGroups(sessionData.groups);
    this.updateActiveGroup(sessionData.activeGroup, true);
    this.updateProjectRouteStations(sessionData.stations);
    this.updateActiveRouteStation(sessionData.activeStation);
  }

  public updateProjectRouteStations(projectRouteStations: ProjectRouteStation[]): void {
    projectRouteStations.sort((a, b) => {
      if (a.x > b.x) {
        return -1;
      } else if (a.x < b.x) {
        return 1
      } else {
        if (a.y > b.y) {
          return 1
        } else if (a.y < b.y) {
          return -1
        } else {
          return 0
        }
      }
    });

    this.projectRouteStationSubject$.next(projectRouteStations);
    if (projectRouteStations[0]) {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionService.getProjectSessionData();
      this.getStationDeviceParameter(projectRouteStations);
      this.mapPositionService.setRouteStationMapPoints(projectRouteStations, activeProjectData.activeProjectGroupId)
    } else {
      this.updateProjectRouteStationsWithParam(undefined);
    }
  }

  public updateProjectRouteStationsWithParam(projectRouteStations: ProjectRouteStationWithParam[] | undefined): void {
    this.projectRouteStationWithParamSubject$.next(projectRouteStations);
  }

  public updateDataEndChannels(dataEndChannels: DataEndChannel[]): void {
    this.dataEndChannelSubject$.next(dataEndChannels);
  }

  public updateDataEndChannelErrors(channelErrors: DataEndChannelError[]): void {
    if (channelErrors.length > 0) {
      this.dataEndChannelErrorsSubject$.next(channelErrors);
    }
    else {
      this.dataEndChannelErrorsSubject$.next([]);
    }
  }

  public updateActiveProject(project: Project | undefined, isFromSession: boolean = false): void {
    this.activeProjectSubject$.next(project);
    if (isFromSession) {
      if (project) {
        this.mapPositionService.updateMapPosition({ latitude: project.latitude, longitude: project.longitude, zoom: 8 });
        if (this.userDataService.isAdmin()) { this.projectService.getProjectsAssignedUsers(project.id).subscribe(activeProjectsUsers => this.updateProjectsUser(activeProjectsUsers)); }


        this.projectService.getAllUnassignedRouteStationImages(project.id).subscribe(images => {
          this.imageDataService.updateUnassignedProjectImages(images);
        });
        this.projectService.getAllRouteStationImages(project.id).subscribe(images => {
          this.imageDataService.updateProjectImages(images);
        });
      } else {
        this.mapPositionService.updateMapPosition({ latitude: 52, longitude: 10, zoom: 5 });
      }
      return;
    }
    this.projectDataSessionService.setActiveProjectId(project ? project.id : "");
    this.updateProjectGroups([]);
    this.updateProjectRouteStations([]);
    this.updateActiveGroup(undefined);
    this.updateActiveRouteStation(undefined);

    if (project) {
      if (this.userDataService.isAdmin()) { this.projectService.getProjectsAssignedUsers(project.id).subscribe(activeProjectsUsers => this.updateProjectsUser(activeProjectsUsers)); }

      this.projectService.getProjectGroups(project.id).subscribe(groups => {
        this.updateProjectGroups(groups);
      });
      this.mapPositionService.updateMapPosition({ latitude: project.latitude, longitude: project.longitude, zoom: 8 });

      this.projectService.getAllUnassignedRouteStationImages(project.id).subscribe(images => {
        this.imageDataService.updateUnassignedProjectImages(images);
      });
      this.projectService.getAllRouteStationImages(project.id).subscribe(images => {
        this.imageDataService.updateProjectImages(images);
      });
    } else {
      this.mapPositionService.updateMapPosition({ latitude: 52, longitude: 10, zoom: 5 });
    }
  }

  public updateActiveGroup(group: ProjectGroup | undefined, isFromSession: boolean = false): void {
    this.activeGroupSubject$.next(group);
    if (isFromSession) {
      if (group) {
        this.mapPositionService.updateMapPosition({ latitude: group.latitude, longitude: group.longitude, zoom: 8 });
      }
      return;
    }
    this.projectDataSessionService.setActiveProjectGroupId(group ? group.id : "");
    this.updateActiveRouteStation(undefined);
    if (group === undefined) {
      this.updateProjectRouteStations([]);
      this.updateProjectRouteStationsWithParam(undefined);
      this.mapPositionService.updateMapPosition({ latitude: 52, longitude: 10, zoom: 5 });
    }
    if (group) {
      this.groupService.getProjectRouteStations(group.id).subscribe(routeStations => {
        this.updateProjectRouteStations(routeStations);
      });
      this.mapPositionService.updateMapPosition({ latitude: group.latitude, longitude: group.longitude, zoom: 8 });
    }
  }

  public updateActiveRouteStation(routeStation: ProjectRouteStation | undefined): void {
    this.activeRouteStationSubject$.next(routeStation);
    this.projectDataSessionService.setActiveProjectRouteStationId(routeStation ? routeStation.id : "");
    if (routeStation) {
      this.routeStationService.getDataEndChannels(routeStation.id).subscribe(dataEndChannels => {
        if (!dataEndChannels) { dataEndChannels = []; }
        this.updateDataEndChannels(dataEndChannels);
      });
      this.deviceService.getAllChannelErrors(routeStation.samId).subscribe(channelErrors => {

        this.updateDataEndChannelErrors(channelErrors);

      },
        error => {
          this.updateDataEndChannelErrors([]);
        });
    }
  }

  private getStationDeviceParameter(routeStations: ProjectRouteStation[]): void {
    this.deviceService.getDeviceParameterForAllGroupStations(routeStations[0].groupId).subscribe(deviceParams => {
      this.dataPointService.getRouteStationLatestVoltage(routeStations[0].groupId).subscribe(lastVoltage => {
        let stationsWithDeviceParam: ProjectRouteStationWithParam[] = [];
        for (let routeStation of routeStations) {
          let deviceParameter = deviceParams.find(d => d.deviceId === routeStation.samId);
          if (deviceParameter) {
            let routeStationWithParam: ProjectRouteStationWithParam = new ProjectRouteStationWithParam(
              routeStation, deviceParameter);
            if (lastVoltage)
              routeStationWithParam.routeStation.latestVoltage = lastVoltage.find(lv => lv.deviceId === routeStationWithParam.deviceParameter.deviceId);
            stationsWithDeviceParam.push(routeStationWithParam);
          }
          if (routeStations.length === stationsWithDeviceParam.length) {
            this.updateProjectRouteStationsWithParam(stationsWithDeviceParam);
          }
        }
      })
    });
  }

  public updateFilteredProjects(projects: Project[]): void {
    this.filteredPorjectsSubject$.next(projects);
  }
}
