import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { Observable } from "rxjs";
import { ProjectRouteStation } from "../interfaces/project-route-station.interface";
import { DataEndChannel } from "../interfaces/data-end-channel.interface";
import { RouteStationNote } from "../interfaces/route-station-note";
import { AnimatedImages } from "../interfaces/animated-images";
import { AnimatedImageErrorMessage } from "../../project-images/shared/animated-image-error-message";

@Injectable({
    providedIn: 'root'
})
export class RouteStationService {
    constructor(private readonly httpService: HttpService) { }

    public getRouteStations(): Observable<ProjectRouteStation[]> {
        return this.httpService.get<ProjectRouteStation[]>('RouteStation');
    }

    public getUnassignedRouteStations(): Observable<ProjectRouteStation[]> {
        return this.httpService.get<ProjectRouteStation[]>('RouteStation/?unassigned=true');
    }

    public editProjectRouteStation(stationId: string, station: ProjectRouteStation): Observable<ProjectRouteStation> {
        return this.httpService.put<ProjectRouteStation>('RouteStation/' + stationId, station);
    }

    public getDataEndChannels(stationId: string): Observable<DataEndChannel[]> {
        return this.httpService.get<DataEndChannel[]>('RouteStation/' + stationId + '/DataEndChannel');
    }

    public postRouteStationNote(note: string, guid: string): Observable<RouteStationNote> {
        let body = {
            note: note,
            routeStationId: guid
        }
        return this.httpService.post<RouteStationNote>('RouteStation/RouteStationNote', body);
    }

    public editRouteStationNote(note: string, id: number): Observable<RouteStationNote> {
        let body = {
            note: note,
        }
        return this.httpService.put<RouteStationNote>('RouteStation/RouteStationNote/' + id, body);
    }

    public generateAnimatedImageForRouteStation(stationId: string, animatedImage: AnimatedImages[], stellcode: number): Observable<AnimatedImageErrorMessage[]> {
        return this.httpService.post<AnimatedImageErrorMessage[]>(`RouteStation/` + stationId + `/AnimatedImages?stellcode=${stellcode}`, animatedImage);
    }

    public evaTransfer(oldRouteStation: string, newRouteStation: string): Observable<any> {
        return this.httpService.post<any>(`RouteStation/${oldRouteStation}/EvaTransfer/${newRouteStation}`, {});
    }
}
