<mat-dialog-content>
        <div class="d-flex justify-content-between">
                <h5 class="fw-semibold">Solardetails</h5>
                <div class="d-flex">
                        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg"
                                alt="close-modal-icon" (click)="onCloseClicked()">
                </div>
        </div>
        <div class="d-flex justify-content-around ">
                <div *ngFor="let details of detailObj" class="d-flex flex-column me-4">
                        <div class="w-100 d-flex justify-content-between"> <span class="fw-bold"
                                        *ngIf="details.de === 11">
                                        Hauptsolarregler
                                </span><span class="fw-bold" *ngIf="details.de === 12"> Zusatzsolarregler
                                </span>
                        </div>
                        <!--                         <div class="w-100 d-flex justify-content-between"> <span> Ladezustand:</span> <span> {{
                                        details.ladezustand}}</span></div> -->
                        <!--                         <div class="w-100 d-flex justify-content-between"> <span> Kapazität:</span> <span> {{
                                        details.verbleibenekapazitat}} %</span></div> -->
                        <div class="w-100 d-flex justify-content-between"> <span> Solarleistung: </span> <span>{{
                                        details.solarleistung}} W</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Batteriespannung:</span> <span> {{
                                        details.batteriespannung}} V</span></div>
                        <div class="w-100 d-flex justify-content-between"> <span> Batteriestrom:</span> <span> {{
                                        details.batteriestrom}} A</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Lastspannung:</span> <span> {{
                                        details.lastspannung}} V</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Laststrom: </span> <span>{{
                                        details.laststrom}} A</span></div>


                        <div *ngIf="  details.reglertyp === 2" class="w-100 d-flex justify-content-between"> <span>
                                        Fehlerzustand:</span> <span> {{

                                        getSolarPanelErrorLuminaxString(details.fehlerzustand)}}</span>
                        </div>
                        <div *ngIf="  details.reglertyp === 1" class="w-100 d-flex justify-content-between"> <span>
                                        Fehlerzustand:</span>

                                <span *ngIf="details.fehlerzustand !== 0"><a target="_blank" rel="noopener noreferrer"
                                                [href]=" getSolarPanelErrorVictronString(details.fehlerzustand)">{{details.fehlerzustand}}</a>


                                </span>
                                <span *ngIf="details.fehlerzustand === 0">Ok


                                </span>
                        </div>


                        <div class="w-100 d-flex justify-content-between"> <span> Warnzustand:</span> <span> {{
                                        getSolaWarningStatusString(details.warnzustand) }}</span></div>
                        <div class="w-100 d-flex justify-content-between"><span>
                                        Reglertyp:</span> <span> {{
                                        getSolarPanelTypeString(details.reglertyp) }}</span></div>
                        <div class="w-100 d-flex justify-content-between"><span> Status: </span> <span>{{
                                        getSolarErrorMessagesStatusString( details.status) }}</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"><span> Uhrzeit: </span> <span>
                                        {{ details.dateTime | date: 'dd.MM.yyyy H:mm' }}</span>
                        </div>
                </div>
        </div>
</mat-dialog-content>