import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/shared/http.service';
import { ProjectTimeSwitchBody } from '../../project-time-switch/shared/interfaces/project-time-switch-body';
import { ProjectTimeSwitchInterface } from '../../project-time-switch/shared/interfaces/project-time-switch.interface';

@Injectable({
  providedIn: 'root'
})
export class TimerSwitchService {

  constructor(private readonly httpService: HttpService) { }


  public getAllTimerSwitches(): Observable<ProjectTimeSwitchBody[]> {
    return this.httpService.get<ProjectTimeSwitchBody[]>('TimerSwitch');
  }

  public createTimerSwitch(data: ProjectTimeSwitchBody): Observable<any> {
    return this.httpService.post('TimerSwitch', data)
  }

  public updateTimerSwitch(data: ProjectTimeSwitchBody): Observable<any> {
    return this.httpService.put('TimerSwitch/' + data.id, data)
  }

  public createAQTimer(projectId: number, data: any): Observable<any> {

    return this.httpService.post('TimerSwitch/' + projectId + '/aqtimer', data)
  }

  public createMQTimer(projectId: number, data: any): Observable<any> {
    return this.httpService.post('TimerSwitch/' + projectId + '/mqtimer', data)
  }

  public deleteAQTimer(timerSwitchId: number, aqId: number): Observable<any> {
    return this.httpService.delete('TimerSwitch/' + timerSwitchId + '/aqtimer/' + aqId)
  }

  public deleteMQTimer(timerSwitchId: number, mqId: number): Observable<any> {
    return this.httpService.delete('TimerSwitch/' + timerSwitchId + '/mqtimer/' + mqId)
  }

  public deleteTimerSwitch(timerSwitchId: number): Observable<any> {
    return this.httpService.delete('TimerSwitch/' + timerSwitchId)
  }

  public createBlinkerTimer(projectId: number, data: any): Observable<any> {
    return this.httpService.post('TimerSwitch/' + projectId + '/blinkertimer', data)
  }

  public deleteBlinkerTimer(timerSwitchId: number, blinkerId: number): Observable<any> {
    return this.httpService.delete('TimerSwitch/' + timerSwitchId + '/blinkertimer/' + blinkerId)
  }

  public updateAQTimer(timerSwitchId: number, aq: ProjectTimeSwitchInterface): Observable<any> {
    return this.httpService.put('TimerSwitch/' + timerSwitchId + '/aqtimer/' + aq.id, aq)
  }
  public updateMQTimer(timerSwitchId: number, mq: ProjectTimeSwitchInterface): Observable<any> {
    return this.httpService.put('TimerSwitch/' + timerSwitchId + '/mqtimer/' + mq.id, mq)
  }
  public updateBlinkerTimer(timerSwitchId: number, blinker: ProjectTimeSwitchInterface): Observable<any> {
    return this.httpService.put('TimerSwitch/' + timerSwitchId + '/blinkertimer/' + blinker.id, blinker)
  }
  public assignTimerSwitchToGuids(timerSwitchId: number, guidList: string[]): Observable<any> {
    return this.httpService.post('TimerSwitch/' + timerSwitchId + '/assigntoguids', guidList)
  }

  public getTimerSwitchByProjectId(projectId: number): Observable<any> {
    return this.httpService.get('TimerSwitch/Project/' + projectId)
  }
}
