<ng-container *ngIf="activeGroup; else noActiveGroup">
    <ng-container *ngIf="projectRouteStations; else noRouteStations">
        <div class="text-secondary avs-countdown-refresh d-flex ">
            <div class="d-flex mt-1">
                <div class="d-flex flex-column">
                    <button *canAccess="[1,2,5]" class="btn btn-sm btn-outline-danger rounded-0 me-2"
                        (click)="onSzenarioSelectClicked()">Sonderprogramm
                        auswählen</button>
                    <span *ngIf="scenarios.length > 0; else noScenarioCreated"> {{ activeScenario?.name ?
                        activeScenario!.name : 'Keine Sonderprogramm aktiv' }}</span>
                </div>
                <ng-template #noScenarioCreated>
                    <span>Keine Sonderprogramme erstellt</span>
                </ng-template>
                <mat-slide-toggle *canAccess="[1]" class="pb-2" [checked]="isEditSchemaActive" color="primary"
                    (change)="onEditSchemaBtnChanged()">
                    <span class="text-secondary">Schema bearbeiten</span>
                </mat-slide-toggle>
                <div style="width: 50px;" class="ms-3">
                    <img alt="icon reload schema data" class="opacity-50 me-1" src="assets/icons/arrow-clockwise.svg">
                    <span>{{ countdown }} s</span>
                </div>
            </div>

        </div>
        <div *ngIf="isCompletelyLoaded" class="card rounded-0 avs-schema-div mt-3 shadow">
            <div class="overflow-x-auto" cdkDropListGroup>
                <div class="d-flex">
                    <ng-container *ngFor="let areaA of line.areaA">
                        <div *ngIf="areaA.isActive" class="border-end w-100"
                            [ngStyle]="{'min-width.px': width[areaA.x -1]}">
                            <div class="text-secondary text-center"><avs-line-name [x]="areaA.x" [y]="1"
                                    [group]="this.activeGroup" [editActive]="isEditSchemaActive"
                                    style="max-height: 24px;"></avs-line-name></div>
                            <ng-container *ngIf="isEditSchemaActive">
                                <div *canAccess="[1]">
                                    <img alt="icon add station" class="float-end me-1 avs-icon-add"
                                        style="margin-top: 14px;" src="assets/icons/plus_gray.svg"
                                        (click)="onAddBtnClicked(areaA.x, 1)">
                                </div>
                            </ng-container>
                            <div cdkDropList id="1" [cdkDropListData]="areaA"
                                (cdkDropListDropped)="onDropStation($event)">
                                <div *ngIf="areaA.station"
                                    class="p-3 avs-station d-flex justify-content-center mt-3 avs-schema-area">
                                    <ng-container *ngFor="let station of areaA.station">
                                        <div class="w-100 p-1 position-relative" *canAccess="[1]">
                                            <ng-container *ngIf="isEditSchemaActive">
                                                <avs-project-route-station cdkDrag [routeStation]="station"
                                                    [images]="projectImages" (click)="onStationClicked(station)">
                                                    <!-- channelsError machen probleme -->
                                                </avs-project-route-station>
                                                <img *ngIf="canBeSwapped(station, areaA)"
                                                    src="../../../assets/icons/arrow-left-right.svg"
                                                    class="mt-2 p-2 change-position"
                                                    src="assets/icons/arrow-left-right.svg"
                                                    [matTooltip]="'Z Tauschen' +station.routeStation.samId"
                                                    (click)="changeZWithRightNeighbour(station)">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditSchemaActive">
                                                <avs-project-route-station [routeStation]="station"
                                                    [images]="projectImages" (click)="onStationClicked(station)">
                                                </avs-project-route-station>
                                                <img (click)="navigateToRouteStation(station)"
                                                    class="mt-2 p-2 go-to-avs-settings" src="assets/icons/gear-fill.svg"
                                                    matTooltip="Einstellungen">
                                                <img (click)="navigateToTestarea(station)"
                                                    class="mt-2 p-2 go-to-avs-settings"
                                                    src="assets/icons/wrench-adjustable.svg" matTooltip="Testbereich">
                                                <img (click)="updateSolardata(station)"
                                                    class="mt-2 p-2 go-to-avs-settings"
                                                    src="assets/icons/arrow-repeat.svg"
                                                    matTooltip="Solardaten aktualisieren">
                                            </ng-container>
                                        </div>
                                        <div class="w-100 p-1" *canAccess="[0,2,3,4,5]">
                                            <avs-project-route-station [routeStation]="station" [images]="projectImages"
                                                (click)="onStationClicked(station)">
                                            </avs-project-route-station>
                                            <img (click)="updateSolardata(station)" class="mt-2 p-2 go-to-avs-settings"
                                                src="assets/icons/arrow-repeat.svg"
                                                matTooltip="Solardaten aktualisieren">
                                        </div>
                                        <div class="w-100 p-1" *canAccess="[7]">
                                            <avs-project-route-station [routeStation]="station"
                                                [images]="projectImages">
                                            </avs-project-route-station>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="!areaA.station" cdkDrag class="d-flex justify-content-around">
                                    <div class="avs-schema-area mt-3">
                                    </div>
                                </div>
                            </div>
                            <div class="avs-schema-road" (click)="onRadarLaneAClicked(areaA)">
                                <ng-container *ngFor="let freewayLane of freewayLanes; let i = index">
                                    <div *ngIf="areaA.line.length > 0">
                                        <ng-container *ngIf="areaA.line.length === 1">
                                            <div *ngIf="i+1 !== areaA.line.length" class="avs-schema-road-stripe"></div>
                                        </ng-container>
                                        <ng-container *ngIf="areaA.line.length > 1">
                                            <div *ngIf="areaA.line.length !== freewayLane.id && areaA.line.length === freewayLanes.length"
                                                class="avs-schema-road-stripe">
                                            </div>
                                            <div *ngIf="areaA.line.length !== freewayLanes.length && freewayLanes.length !== freewayLane.id"
                                                class="avs-schema-road-stripe">

                                            </div>
                                        </ng-container>

                                    </div>
                                    <div *ngIf="areaA.line.length === 0">
                                        <div *ngIf="freewayLane.id !== freewayLanes.length"
                                            class="avs-schema-road-stripe"></div>
                                    </div>
                                    <div *ngIf="freewayLane.id === 1" class="avs-schema-road-middle-strip">
                                        <div [class]="areaA.colorArr[i] + ' opacity-50 h-100'">
                                        </div>
                                    </div>
                                    <div *ngIf="freewayLane.id > 1" class="avs-schema-road-middle-strip">

                                        <div *ngIf="areaA.line.length === 1" [class]=" 'opacity-50  h-100'">
                                        </div>
                                        <div *ngIf="areaA.line.length > 1" class="avs-schema-road-middle-strip">
                                            <div [class]="areaA.colorArr[i] + ' opacity-50 h-100'">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="avs-schema-road-end"></div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="!line.areaA[0].isActive" class="ms-2"> <br></div>
                    <ng-container *ngIf="isEditSchemaActive">
                        <ng-container *canAccess="[1]">
                            <div *ngIf="!line.areaA[14].isActive">
                                <img alt="icon arrow right" src="assets/icons/arrow-bar-right.svg"
                                    class="mt-1 ms-2 me-2 avs-schema-line-arrow p-1" height="32"
                                    (click)="onExpandSchemeClicked()">
                            </div>
                            <div *ngIf="line.areaA[14].isActive">
                                <img alt="icon arrow left" src="assets/icons/arrow-bar-left.svg"
                                    class="mt-1 ms-2 me-2 avs-schema-line-arrow p-1" height="32"
                                    (click)="onCollapseSchemeClicked()">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="avs-schema-project d-flex position-absolute" [style.margin-top.px]="trafficSignPosition"
                    style="z-index: 500">
                    <div class="ms-2 me-2">{{activeGroup.name}}</div>
                    <div class="avs-schema-project-traffic-sign"></div>
                </div>
                <div class="d-flex">
                    <ng-container *ngFor="let areaB of line.areaB">
                        <div *ngIf="areaB.isActive" class="border-end w-100"
                            [ngStyle]="{'min-width.px': width[areaB.x -1]}">
                            <div *ngIf="!line.areaA[0].isActive" class="avs-schema-road"
                                (click)="onRadarLaneBClicked(areaB)"> <!-- Klick Methode für Sensor einbauen -->
                                <ng-container *ngFor="let freewayLane of freewayLanes; let i = index">
                                    <div *ngIf="areaB.line.length > 0">
                                        <ng-container *ngIf="areaB.line.length === 1">
                                            <div *ngIf="i+1 !== areaB.line.length" class="avs-schema-road-stripe"></div>
                                        </ng-container>

                                        <ng-container *ngIf="areaB.line.length > 1">
                                            <div *ngIf="areaB.line.length !== freewayLane.id && areaB.line.length === freewayLanes.length"
                                                class="avs-schema-road-stripe">
                                            </div>
                                            <div *ngIf="areaB.line.length !== freewayLanes.length && freewayLanes.length !== freewayLane.id"
                                                class="avs-schema-road-stripe">

                                            </div>
                                        </ng-container>


                                    </div>
                                    <div *ngIf="areaB.line.length === 0">
                                        <div *ngIf="freewayLane.id !== freewayLanes.length"
                                            class="avs-schema-road-stripe"></div>
                                    </div>
                                    <div *ngIf="freewayLane.id === 1" class="avs-schema-road-middle-strip">
                                        <div [class]="areaB.colorArr[i] + ' opacity-50 h-100'">
                                        </div>
                                    </div>
                                    <div *ngIf="freewayLane.id > 1" class="avs-schema-road-middle-strip">
                                        <div *ngIf="areaB.line.length === 1" [class]="'opacity-50 h-100'">
                                        </div>
                                        <div *ngIf="areaB.line.length > 1" class="avs-schema-road-middle-strip">
                                            <div [class]="areaB.colorArr[i] + ' opacity-50 h-100'">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="avs-schema-road-end"></div> <!-- Klick Methode für Sensor einbauen -->
                            </div>
                            <div class="text-secondary text-center"><avs-line-name [x]="areaB.x" [y]="2"
                                    [group]="this.activeGroup" [editActive]="isEditSchemaActive"
                                    style="max-height: 24px;"></avs-line-name></div>
                            <ng-container *ngIf="isEditSchemaActive">
                                <div *canAccess="[1]">
                                    <img alt="add Station Icon" class="float-end me-1 avs-icon-add"
                                        style="margin-top: -4px;" src="assets/icons/plus_gray.svg"
                                        (click)="onAddBtnClicked(areaB.x, 2)">
                                </div>
                            </ng-container>
                            <div cdkDropList id="2" [cdkDropListData]="areaB"
                                (cdkDropListDropped)="onDropStation($event)">
                                <div *ngIf="areaB.station"
                                    class="p-3 avs-station d-flex justify-content-center mt-3 avs-schema-area">
                                    <ng-container *ngFor="let station of areaB.station">
                                        <!-- vllt doch | keyvalue pipe? mal mit auseinander setzen -->
                                        <div class="w-100 p-1 position-relative" *canAccess="[1]">
                                            <ng-container *ngIf="isEditSchemaActive">
                                                <avs-project-route-station cdkDrag [routeStation]="station"
                                                    [images]="projectImages" (click)="onStationClicked(station)">
                                                </avs-project-route-station>
                                                <img *ngIf="canBeSwapped(station, areaB)"
                                                    src="../../../assets/icons/arrow-left-right.svg"
                                                    class="mt-2 p-2 change-position"
                                                    src="assets/icons/arrow-left-right.svg" matTooltip="Z Tauschen"
                                                    (click)="changeZWithRightNeighbour(station)">
                                            </ng-container>
                                            <ng-container *ngIf="!isEditSchemaActive">
                                                <avs-project-route-station [routeStation]="station"
                                                    [images]="projectImages" (click)="onStationClicked(station)">
                                                </avs-project-route-station>
                                                <img (click)="navigateToRouteStation(station)"
                                                    class="mt-2 p-2 go-to-avs-settings" src="assets/icons/gear-fill.svg"
                                                    matTooltip="Einstellungen">
                                                <img (click)="navigateToTestarea(station)"
                                                    class="mt-2 p-2 go-to-avs-settings"
                                                    src="assets/icons/wrench-adjustable.svg" matTooltip="Testbereich">
                                                <img (click)="updateSolardata(station)"
                                                    class="mt-2 p-2 go-to-avs-settings"
                                                    src="assets/icons/arrow-repeat.svg"
                                                    matTooltip="Solardaten aktualisieren">

                                            </ng-container>
                                        </div>
                                        <div class="w-100 p-1" *canAccess="[0,2,3,4,5]">
                                            <avs-project-route-station [routeStation]="station" [images]="projectImages"
                                                (click)="onStationClicked(station)">
                                            </avs-project-route-station>
                                            <img (click)="updateSolardata(station)" class="mt-2 p-2 go-to-avs-settings"
                                                src="assets/icons/arrow-repeat.svg"
                                                matTooltip="Solardaten aktualisieren">
                                        </div>
                                        <div class="w-100 p-1" *canAccess="[7]">
                                            <avs-project-route-station [routeStation]="station"
                                                [images]="projectImages">
                                            </avs-project-route-station>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="!areaB.station" cdkDrag class="d-flex justify-content-around">
                                    <div class="avs-schema-area"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="!line.areaB[0].isActive" class="ms-2">keine Station hinzugefügt</div>
                    <ng-container *ngIf="isEditSchemaActive">
                        <ng-container *canAccess="[1]">
                            <div *ngIf="!line.areaB[14].isActive">
                                <img alt="icon arrow right" src="assets/icons/arrow-bar-right.svg"
                                    class="mt-1 ms-2 me-2 avs-schema-line-arrow p-1" height="32"
                                    (click)="onExpandSchemeClicked()">
                            </div>
                            <div *ngIf="line.areaA[14].isActive">
                                <img alt="icon arrow left" src="assets/icons/arrow-bar-left.svg"
                                    class="mt-1 ms-2 me-2 avs-schema-line-arrow p-1" height="32"
                                    (click)="onCollapseSchemeClicked()">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #noActiveGroup>
    <p class="mt-3 fw-semibold text-secondary">Keine Gruppe ausgewählt!</p>
</ng-template>
<ng-template #noRouteStations>
    <p class="mt-3 fw-semibold text-secondary">Keine Stationen in der Gruppe</p>
</ng-template>