<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Fallback (Blinker)</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>


    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="avs-route-station-settings-dropdown-container justify-content-between"
            style="width: 240px !important">
            <span class="ms-1 fw-semibold text-secondary">Funktion</span>
            <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                role="button" (click)="onFunctionDropdownClicked()">
                <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false" *ngIf="isFunctionDropdownOpen"
                class="avs-route-station-settings-dropdown-container">
            </avs-dropdown>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button [disabled]="onFunctionSelected" class="btn btn-sm btn-outline-success rounded-0"
        (click)="onSubmitBtnClicked()">
        Erstellen
    </button>
</mat-dialog-actions>