<div class="m-2" style="min-width: 400px;">
    <div class="d-flex justify-content-between border-bottom">
        <h5 class="fw-semibold">{{data.congfig ? 'Bericht Einstellungen bearbeiten' : 'Bericht Einstellungen anlegen'}}
        </h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseModalClicked()">
    </div>
    <div class="mt-2">
        <div class="d-flex justify-content-between">
            <div *ngIf="!isEditUserActive">Benutzer: <span class="text-secondary">{{selectedUser.name}}</span></div>
            <div *ngIf="isEditUserActive">
                <div [ngClass]="isUserDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                    role="button" (click)="isUserDropdownOpen = !isUserDropdownOpen">
                    <div class="ms-2 p-1">{{this.selectedUser.name}}</div>
                    <img *ngIf="!isUserDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isUserDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="userSelectInput" [activeItem]="selectedUser"
                    (onItemClicked)="onUserItemClicked($event)" [isHeaderActive]="false" *ngIf="isUserDropdownOpen"
                    [isSmallDropdown]="true">
                </avs-dropdown>
            </div>
            <img class="ms-5 p-2 avs-report-config-edit-pencil"
                [src]="isEditUserActive ? 'assets/icons/pencil.svg' : 'assets/icons/pencil-grey.svg'" role="button"
                (click)="onEditUserBtnClicked()" alt="edit Icon">
        </div>
        <div>
            <div>
                <mat-form-field appearance="fill"> <!-- TODO: nochmal überarbeiten? -->
                    <mat-label>Gruppe</mat-label>
                    <input matInput type="text" [(ngModel)]="searchFilter" (keyup)="filterDataSource()"
                        placeholder="Suche...">
                    <mat-select [(value)]="selectedGroup" (selectionChange)="setGroup(selectedGroup)">
                        <mat-option *ngFor="let group of groupSelectInput" [value]="group">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div class="mt-3 mb-1">Bericht senden:</div>
            <div class="d-flex">
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="we" [checked]="sendMailInterval === 1"
                        (change)="sendMailInterval = 1">
                    <label class="form-check-label" [ngClass]="sendMailInterval === 1 ? '' : 'text-secondary'" for="we">
                        wöchentlich
                    </label>
                </div>
                <div class="form-check ms-3">
                    <input class="form-check-input" type="radio" id="mo" [checked]="sendMailInterval === 2"
                        (change)="sendMailInterval = 2">
                    <label class="form-check-label" [ngClass]="sendMailInterval === 2 ? '' : 'text-secondary'" for="mo">
                        monatlich
                    </label>
                </div>
            </div>
        </div>
        <div>
            <div class="mt-3 mb-1">Datei Format:</div>
            <div class="d-flex">
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="pdf" [checked]="sendDataType === 'pdf'"
                        (change)="sendDataType = 'pdf'">
                    <label class="form-check-label" [ngClass]="sendDataType === 'pdf' ? '' : 'text-secondary'"
                        for="pdf">
                        PDF
                    </label>
                </div>
                <div class="form-check ms-3">
                    <input class="form-check-input" type="radio" id="csv" [checked]="sendDataType === 'csv'"
                        (change)="sendDataType = 'csv'">
                    <label class="form-check-label" [ngClass]="sendDataType === 'csv' ? '' : 'text-secondary'"
                        for="csv">
                        CSV
                    </label>
                </div>
                <div class="form-check ms-3">
                    <input class="form-check-input" type="radio" id="ascii" [checked]="sendDataType === 'ascii'"
                        (change)="sendDataType = 'ascii'">
                    <label class="form-check-label" [ngClass]="sendDataType === 'ascii' ? '' : 'text-secondary'"
                        for="ascii">
                        ASCII
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="border-top mt-2 d-flex justify-content-end">
        <button class="btn btn-sm btn-outline-danger rounded-0 m-2" (click)="onCloseModalClicked()">
            Abbrechen
        </button>
        <button class="btn btn-sm btn-outline-success rounded-0 m-2" (click)="onSaveBtnClicked()">
            Speichern
        </button>
    </div>
</div>